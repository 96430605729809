import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToggleList.module.scss';
import '@kenshooui/react-multi-select/dist/style.css';
import MultiSelect from '@kenshooui/react-multi-select';
import ImportedCalendar from '@shared/models/imported_calendar';
import Item from '@/components/ToggleList/Item';
import ToggleListContext from '@/services/contexts/toggle_list';

export function importedCalendarToItem({ id, summary }) {
  return { id, label: summary };
};

export function itemToImportedCalendar({ id, label }) {
  return ImportedCalendar.fromPlainObject({ id, summary: label });
}

function ToggleList({ items, itemColors, selectedItems, onChange, blinkingItems }) {
  const itemHeight = 36;
  const coloredItems = items.map(({ id, label }) => { return { id, label, color: itemColors.get(id) } });

  const context = {
    blinkingItems,
  };

  return (
    <ToggleListContext.Provider value={ context }>
      <MultiSelect
        items={ coloredItems }
        selectedItems={ selectedItems }
        onChange={ onChange }
        showSearch={ false }
        showSelectAll={ false }
        showSelectedItems={ false }
        itemRenderer={ Item }
        itemHeight={ itemHeight }
        responsiveHeight={ '70px' }
        wrapperClassName={ styles.wrapper }
      />
    </ToggleListContext.Provider>
  );
}

const itemShape = PropTypes.shape({
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
});

ToggleList.propTypes = {
  items: PropTypes.arrayOf(itemShape).isRequired,
  itemColors: PropTypes.instanceOf(Map), // itemID -> color
  selectedItems: PropTypes.arrayOf(itemShape).isRequired,
  onChange: PropTypes.func.isRequired,
  blinkingItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ToggleList.defaultProps = {
  itemColors: new Map(),
};

export default ToggleList;
