import React, { useState } from 'react';
import styles from './CreatingMeta.module.scss';
import Textbox from '@/components/Textbox';
import Primary from '@/components/Button/Primary';
import { useDispatch } from 'react-redux';
import { updateName } from '@/slices/meetup';
import { popPopup } from '@/slices/view';
import { useFirstName } from '@/slices/me';
import { useAnalytics } from '@/services/analytics';
import { isBrowser } from 'react-device-detect';
import InviteDrawer from '@/components/InviteDrawer';
import Logo from '@/components/Logo';

export default function CreatingMeta() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const firstName = useFirstName();
  const [ name, setName ] = useState(`${ firstName }'s Meetup`);
  const [ updatingName, setUpdatingName ] = useState(false);

  async function next() {
    setUpdatingName(true);
    await dispatch(updateName(name));
    analytics('creator_set_name');
    setUpdatingName(false);
    dispatch(popPopup());
  }

  if(isBrowser) {
    return (
      <div className={ styles.meta }>
        { isBrowser && (
          <header>
            <Logo />
            <span>Combine your friends' calendars to find a meetup time</span>
          </header>
        ) }

        { !isBrowser && (
          <header>
            <h1>Hi! Welcome to your meetup</h1>
            <h2>Lets give your meetup a name</h2>
          </header>
        ) }

        <section className={ styles.columns }>
          <section>
            <h3 className={ styles.primary }>Required</h3>

            { isBrowser && (
              <header>
                <h1>Ready to start a meetup?</h1>
                <h2>Give your meetup a name</h2>
              </header>
            ) }

            <section className={ styles.name }>
              <Textbox value={ name } onChange={ setName } />
            </section>

            <Primary onClick={ next } loading={ updatingName }>Continue</Primary>
          </section>

          <div className={ styles.separator } />

          <section>
            <h3 className={ styles.light }>Optional</h3>

            <InviteDrawer />
          </section>
        </section>
      </div>
    );
  }

  return (
    <div className={ styles.meta }>
      <header>
        <h1>Hi! Welcome to your meetup</h1>
        <h2>Lets give your meetup a name</h2>
      </header>

      <section className={ styles.name }>
        <Textbox value={ name } onChange={ setName } />
      </section>

      <Primary onClick={ next } loading={ updatingName }>Continue</Primary>
    </div>
  );
}
