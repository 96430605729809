import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './TimePicker.module.scss';
import moment from '@shared/services/moment';

function withoutDate(moment) {
  return moment.clone().set({ day: 1, month: 1, year: 2005 }); // Set it to an arbitrary date
}

function TimePicker({ time, setTime, min, max, step }) {
  [ time, min, max ] = [ time, min, max ].map(withoutDate);

  function onChange(event) {
    const newDate = moment.unix(event.target.value);
    setTime(newDate);
  }

  const options = useMemo(() => {
    const elems = [];

    for(const cur = min.clone(); !cur.isAfter(max); cur.add(step)) {
      elems.push(<option key={ cur.unix() } value={ cur.unix() }>{ cur.format('hh:mm A') }</option>);
    }

    return elems;
  }, [ min, max, step ]);

  return (
    <select value={ time.unix() } onChange={ onChange } className={ styles.picker }>
      { options }
    </select>
  );
}

// TimePicker uses moments to represent times.
TimePicker.propTypes = {
  time: PropTypes.instanceOf(moment).isRequired,
  setTime: PropTypes.func.isRequired,
  min: PropTypes.instanceOf(moment).isRequired,
  max: PropTypes.instanceOf(moment).isRequired,
  step: PropTypes.any, // instance of moment.duration
};

TimePicker.defaultProps = {
  step: moment.duration(30, 'minutes'),
}

export default TimePicker;
