import React from 'react';
import PropTypes from 'prop-types';
import styles from './MeetupURLCopied.module.scss';

function MeetupURLCopied({ closeToast }) {
  return (
    <section className={ styles.toast }>
      Meetup URL copied
    </section>
  );
}

MeetupURLCopied.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default MeetupURLCopied;
