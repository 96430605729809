import React, { useState } from 'react';
import styles from './Splash.module.scss';
import Logo from '@/components/Logo';
import Tertiary from '@/components/Button/Tertiary';
import Step from './Step';
import { Swipeable } from 'react-swipeable';
import { useDispatch } from 'react-redux';
import { updateScreen, updateDrawerOpen, pushPopup, clearPopups, AppScreen, Popup } from '@/slices/view';
import combine from './combine.png';
import privacy from './privacy.png';
import agree from './agree.png';
import { useAnalytics } from '@/services/analytics';

const steps = [
  {
    title: 'Build a combined schedule',
    subtitle: `We match people's calendars to find out when everyone is free to meetup`,
    backgroundColor: '#6496FB',
    image: combine,
  },
  {
    title: 'Your events stay private',
    subtitle: `Others can see when you're busy, but not what you're doing at those times.`,
    backgroundColor: '#55CEEB',
    image: privacy,
  },
  {
    title: 'Agree on a meetup time',
    subtitle: `We'll add your meetup time straight to your calendar`,
    backgroundColor: '#2BE1BE',
    image: agree,
  },
];

export default function Splash() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const [ step, setStep ] = useState(0);

  function skip() {
    analytics('splash_skip');
    finish();
  }

  function finish() {
    dispatch(updateScreen(AppScreen.MAIN));
    dispatch(updateDrawerOpen(true));
    dispatch(clearPopups());
    dispatch(pushPopup(Popup.SIGN_IN));
  }

  function next() {
    const nextStep = step + 1;
    if(nextStep === steps.length) {
      analytics('splash_finish_natural');
      finish();
      return;
    }

    setStep(nextStep);
    analytics('splash_next');
  }

  function prev() {
    setStep(Math.max(step - 1, 0));
    analytics('splash_prev');
  }

  function onSwipe({ dir }) {
		if(dir === 'Left') {
			next();
		} else if(dir === 'Right') {
			prev();
		}
	}

  return (
    <div className={ styles.splash }>
      <main onClick={ next }>
        <Swipeable onSwiped={ onSwipe }>
          <Step step={ steps[step] } stepNumber={ step } maxStep={ steps.length } />
        </Swipeable>
      </main>

      <section className={ styles.underview }>
        <div className={ styles.logo }>
          <Logo />
        </div>

        <div className={ styles.skip }>
          <Tertiary onClick={ skip }>Skip</Tertiary>
        </div>
      </section>
    </div>
  );
}
