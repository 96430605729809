export default function(url) {
  const tempWindow = window.open(url, '_blank');

  return new Promise(resolve => {
    checkClosed(tempWindow, resolve);
  });
}

function checkClosed(tempWindow, resolve) {
  if(tempWindow.closed) {
    window.focus();
    resolve();
    return;
  }

  setTimeout(() => checkClosed(tempWindow, resolve), 500);
}
