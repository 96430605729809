import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './DatePicker.module.scss';
import moment from '@shared/services/moment';

function DatePicker({ date, setDate, min, max }) {
  function onChange(event) {
    const newDate = moment.unix(event.target.value);
    setDate(newDate);
  }

  const options = useMemo(() => {
    const elems = [];

    for(const cur = min.clone().startOf('day'); !cur.isAfter(max.endOf('day')); cur.add(1, 'day')) {
      elems.push(<option key={ cur.unix() } value={ cur.unix() }>{ cur.format('ddd MMM DD') }</option>);
    }

    return elems;
  }, [ min, max ]);

  return (
    <select value={ date.clone().startOf('day').unix() } onChange={ onChange } className={ styles.picker }>
      { options }
    </select>
  );
}

// DatePicker uses moments to represent dates. All moments should be .startOf('day').
DatePicker.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
  setDate: PropTypes.func.isRequired,
  min: PropTypes.instanceOf(moment).isRequired,
  max: PropTypes.instanceOf(moment).isRequired,
};

export default DatePicker;
