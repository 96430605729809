import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import * as api from '@/services/api';
import { fetchMe, useMe } from '@/slices/me';
import uniqBy from 'lodash.uniqby';

export const meetupSlice = createSlice({
  name: 'meetup',

  initialState: {
    value: {
      people: [],
      busySpans: [],
      selectedTime: {},
      timeRange: {},
      dates: [],
    },
  },

  reducers: {
    fetchMeetupSuccess: (state, { payload }) => {
      state.value = payload;
    },

    updateNameSuccess: (state, { payload }) => {
      state.value.name = payload;
    },

    updateSelectedTimeSuccess: (state, { payload }) => {
      state.value.selectedTime = payload;
    },

    deleteBusySpanSuccess: (state, { payload }) => {
      state.value.busySpans = state.value.busySpans.filter(busySpan => busySpan.id !== payload.id);
    },
  },
});

export const {
  fetchMeetupSuccess,
  updateNameSuccess,
  updateSelectedTimeSuccess,
  deleteBusySpanSuccess,
} = meetupSlice.actions;

export const fetchMeetup = meetupID => async dispatch => {
  const meetup = await api.fetchMeetup(meetupID);
  return dispatch(fetchMeetupSuccess(meetup));
}

export const updateName = name => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.updateName(meetupID, name);
  return dispatch(updateNameSuccess(name));
});

export const updateSelectedTime = timeSpan => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.updateSelectedTime(meetupID, timeSpan);
  return dispatch(updateSelectedTimeSuccess(timeSpan));
});

export const deleteBusySpan = busySpan => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.deleteBusySpan(meetupID, busySpan);
  dispatch(deleteBusySpanSuccess(busySpan));
  return dispatch(fetchMe()); // Me also stores some busy spans, so it needs to be in sync
});

// =================================
// Selectors
// =================================
export function useMeetup() {
  return useSelector(state => state.meetup.value);
}

// Returns whether or not "me" can schedule "meetup"
export function useCanSchedule() {
  const meetup = useMeetup();
  const me = useMe();

  const numPeopleWithBusySpans = uniqBy(meetup.busySpans, busySpan => busySpan.person.id).length;
  return me && numPeopleWithBusySpans > 1;
}

// =================================
// Internal Helpers
// =================================
// Wraps a Redux action to also include this page's current meetup ID
export const asyncMeetupAction = action => async (dispatch, getState) => {
  const meetupID = getState().meetup.value.id;
  return action(dispatch, meetupID);
};

export default meetupSlice.reducer;
