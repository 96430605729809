import React from 'react';
import PropTypes from 'prop-types';
import styles from './Step.module.scss';
import ProgressDots from '@/components/ProgressDots';

function Step({ step, stepNumber, maxStep }) {
  const { title, subtitle, backgroundColor, image } = step;

  return (
    <section className={ styles.step } style={{ backgroundColor }}>
      <section>
        <img alt={ title } src={ image } />
        <h1>{ title }</h1>
        <h2>{ subtitle }</h2>
      </section>

      <section className={ styles.progress }>
        <ProgressDots step={ stepNumber } min={ 0 } max={ maxStep } />
      </section>
    </section>
  );
}

Step.propTypes = {
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  stepNumber: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
};

export default Step;
