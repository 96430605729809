// Taken from https://github.com/jasonsalzman/react-add-to-calendar/
// Cleaned up & kept only the Google Calendar pieces
import moment from '@shared/services/moment';

function formatTime(time) {
  return moment(time).utc().format('YYYYMMDDTHHmmssZ').replace('+00:00', 'Z');
}

export function google(_ref) {
  var title = _ref.title,
      timeSpan = _ref.timeSpan;
  var url = new URL('https://calendar.google.com/calendar/render');
  url.searchParams.append('action', 'TEMPLATE');
  url.searchParams.append('dates', "".concat(formatTime(timeSpan.start), "/").concat(formatTime(timeSpan.end)));
  url.searchParams.append('text', title);
  return url.toString();
}