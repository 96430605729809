import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import * as api from '@/services/api';
import { asyncMeetupAction, fetchMeetup, fetchMeetupSuccess } from '@/slices/meetup';
import CalendarColorCycler from '@shared/services/calendar_color_cycler';
import { parseName } from 'humanparser';
import SequentialPromiseQueue from '@/services/sequential_promise_queue';

export const meSlice = createSlice({
  name: 'me',

  initialState: {
    value: null,
    forceImport: false,
  },

  reducers: {
    fetchMeSuccess: (state, { payload }) => {
      state.value = payload;
    },

    updateForceImport: (state, { payload }) => {
      state.forceImport = payload;
    },

    signOutSuccess: state => {
      state.value = null;
    },

    optimisticallyUpdateVisibleImportedCalendars: (state, { payload }) => {
      for(const calendar of state.value.importedCalendars) {
        calendar.visible = payload.some(newVisible => newVisible.id === calendar.id);
      }
    },
  },
});

export const {
  fetchMeSuccess,
  updateForceImport,
  signOutSuccess,
  optimisticallyUpdateVisibleImportedCalendars,
} = meSlice.actions;

// Allow overriding the meetup ID so we can fetch the user before the meetup has loaded
export const fetchMe = overrideMeetupID => asyncMeetupAction(async (dispatch, meetupID) => {
  const me = await api.fetchMe(overrideMeetupID || meetupID);
  return dispatch(fetchMeSuccess(me));
});

export const googleSignIn = ({ importCalendar, forceImport }) => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.googleSignIn({ meetupID, importCalendar, forceImport });
  return Promise.all([
    dispatch(fetchMe()),
    dispatch(fetchMeetup(meetupID)),
  ]);
});

export const outlookSignIn = ({ importCalendar, forceImport }) => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.outlookSignIn({ meetupID, importCalendar, forceImport });
  return Promise.all([
    dispatch(fetchMe()),
    dispatch(fetchMeetup(meetupID)),
  ]);
});

export const createBusySpans = busySpans => asyncMeetupAction(async (dispatch, meetupID) => {
  const { meetup, me } = await api.createBusySpans(meetupID, busySpans);
  dispatch(fetchMeSuccess(me));
  dispatch(fetchMeetupSuccess(meetup));
});

export const deleteImportedCalendars = () => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.deleteImportedCalendars(meetupID);
  return Promise.all([
    dispatch(fetchMe()),
    dispatch(fetchMeetup(meetupID)),
  ]);
});

export const signOut = () => asyncMeetupAction(async (dispatch, meetupID) => {
  await api.signOut(meetupID);
  return dispatch(signOutSuccess());
});

// Optimistically update the list of imported calendars that are visible
const visibleCalendarUpdaters = new SequentialPromiseQueue();
export const updateVisibleImportedCalendars = calendars => asyncMeetupAction(async (dispatch, meetupID) => {
  dispatch(optimisticallyUpdateVisibleImportedCalendars(calendars));
  visibleCalendarUpdaters.add(() => api.updateVisibleImportedCalendars(meetupID, calendars));
});

// =================================
// Selectors
// =================================
export function useMe() {
  return useSelector(state => state.me.value);
}

export function useForceImport() {
  return useSelector(state => state.me.forceImport);
}

export function useFirstName() {
  const name = useSelector(state => state.me.value.person.name);
  return parseName(name).firstName;
}

export function useCalendarColors() {
  const me = useMe();
  if(!me) {
    return new Map();
  }

  const colors = new CalendarColorCycler();
  return new Map(
    me.importedCalendars.map(({ id }, idx) => [ id, colors.getColorAtPosition(idx) ])
  );
}

export function usePublicBusySpans() {
  const me = useMe();
  if(!me) {
    return [];
  }

  return me.busySpans.filter(busySpan => {
    const { calendarID } = me.busySpanCalendarEvents[busySpan.id] || {};
    if(!calendarID) {
      return true;
    }

    return me.importedCalendars.some(calendar => calendar.visible && calendar.id === calendarID);
  });
}

export default meSlice.reducer;
