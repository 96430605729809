import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MeetupContainer from '@/components/MeetupContainer';
import LandingContainer from '@/containers/LandingContainer';
import NotFoundContainer from '@/containers/NotFoundContainer';
import PrivacyContainer from '@/containers/PrivacyContainer';
import store from '@/services/store';
import { Provider } from 'react-redux';

function App() {
	return (
		<Router key='router'>
			<Switch>
				<Route exact path='/' component={ LandingContainer } />
				<Route exact path='/notfound' component={ NotFoundContainer } />
				<Route exact path='/privacy' component={ PrivacyContainer } />

				<Route path='/:meetupID'>
					<Provider store={ store }>
						<MeetupContainer />
					</Provider>
				</Route>
			</Switch>
		</Router>
	);
}

ReactDOM.render(<App />, document.getElementById('root'));
