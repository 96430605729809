import React from 'react';
import { useSelectedBusySpan } from '@/slices/calendar';
import { useMe } from '@/slices/me';
import DeleteBusySpan from './DeleteBusySpan';
import BusyPeople from './BusyPeople';

export default function BusySpanDetails() {
  const busySpan = useSelectedBusySpan();
  const me = useMe();

  if(me && busySpan.person && busySpan.person.id === me.person.id) {
    return <DeleteBusySpan />;
  } else {
    return <BusyPeople />;
  }
}
