import React from 'react';
import styles from './Logo.module.scss';
import logo from './logo.png';

export default function Logo() {
	return (
		<div className={ styles.logo }>
			<img src={ logo } alt='Logo' />

			<div className={ styles.name }>Calsynk</div>
			<div className={ styles.phase }>BETA</div>
		</div>
	);

}
