import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressDots.module.scss';
import classnames from 'classnames/bind';

const c = classnames.bind(styles);

function ProgressDots({ step, min, max }) {
  const dots = [];
  for(let i = min; i <= max; i++) {
    dots.push(
      <div key={ i } className={ c('dot', { current: step === i }) } />
    );
  }

  return (
    <div className={ styles.container }>
      { dots }
    </div>
  );
}

ProgressDots.propTypes = {
  // step within [min, max]
  step: PropTypes.number.isRequired,

  // min and max are inclusive
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default ProgressDots;
