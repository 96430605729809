import React, { useState } from 'react';
import styles from './ExtraDrawerActions.module.scss';
import { ReactComponent as Toggle } from './extra-actions-toggle.svg';
import Tertiary from '@/components/Button/Tertiary';
import { useDispatch } from 'react-redux';
import { deleteImportedCalendars } from '@/slices/me';
import { ReactComponent as AddManually } from './add-primary.svg';
import { ReactComponent as DeleteCalendars } from './delete-calendars-primary.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import { updateDrawerOpen } from '@/slices/view';
import { updateDragAction, DragAction } from '@/slices/calendar';

export default function ExtraDrawerActions() {
  const dispatch = useDispatch();
  const [ open, setOpen ] = useState(false);

  function addManually() {
    dispatch(updateDrawerOpen(false));
    dispatch(updateDragAction(DragAction.ADD_BUSY_SPAN));
  }

  return (
    <div className={ styles.container }>
      <Toggle onClick={ () => setOpen(!open) } />

      { open && (
        <OutsideClickHandler onOutsideClick={ () => setOpen(false) }>
          <ul className={ styles.actions }>
            <li>
              <Tertiary
                onClick={ () => dispatch(deleteImportedCalendars()) }
                icon={ <DeleteCalendars /> }
                className={ styles.deleteCalendars }
                key='deleteCalendars'
              >
                Delete all imported calendars
              </Tertiary>
            </li>

            <li>
              <Tertiary
                onClick={ addManually }
                icon={ <AddManually /> }
                className={ styles.addingManually }
              >
                Add a busy time manually
              </Tertiary>
            </li>
          </ul>
        </OutsideClickHandler>
      ) }
    </div>
  );
}
