import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const AppScreen = {
  LOADING: 'loading',
  SPLASH: 'splash',
  MAIN: 'main',
};

export const Popup = {
  CREATING: 'creating',
  INVITE: 'invite',
  SIGN_IN: 'sign_in',
  SCHEDULE: 'schedule',
  CONFIRMED: 'confirmed',
  CANCEL: 'cancel',
  BUSY_SPAN_DETAILS: 'busy_span_details',
};

export const viewSlice = createSlice({
  name: 'view',

  initialState: {
    screen: AppScreen.LOADING,
    popups: [],
    drawerOpen: false,
  },

  reducers: {
    updateScreen: (state, { payload }) => {
      state.screen = payload;
    },

    pushPopup: (state, { payload }) => {
      state.popups = [ ...state.popups, payload ];
    },

    popPopup: state => {
      state.popups = state.popups.filter((_, idx) => idx !== state.popups.length - 1);
    },

    replaceCurrentPopup: (state, { payload }) => {
      state.popups = [ ...state.popups.slice(0, -1), payload ];
    },

    clearPopups: state => {
      state.popups = [];
    },

    updateDrawerOpen: (state, { payload }) => {
      state.drawerOpen = payload;
    },
  },
});

export const {
  updateScreen,
  pushPopup,
  popPopup,
  replaceCurrentPopup,
  clearPopups,
  updateDrawerOpen,
} = viewSlice.actions;

// =================================
// Selectors
// =================================
export function useView() {
  return useSelector(state => state.view);
}

// Returns the top-most popup or null if there is none
export function useCurrentPopup() {
  const { popups } = useView();
  if(popups.length === 0) {
    return null;
  }

  return popups[popups.length - 1];
}

export default viewSlice.reducer;
