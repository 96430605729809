import React from 'react';
import PropTypes from 'prop-types';
import styles from './CantSchedule.module.scss';

function CantSchedule({ closeToast }) {
  return (
    <section className={ styles.toast }>
      <h1>Invite more friends</h1>
      <p>You can't schedule a meetup time with only yourself. Invite others to join.</p>
    </section>
  );
}

CantSchedule.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default CantSchedule;
