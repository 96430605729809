import React from 'react';
import styles from './BusyPeople.module.scss';
import People from './People';

export default function BusyPeople() {
  return (
    <div className={ styles.container }>
      <People />
    </div>
  );
}
