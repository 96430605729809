import React from 'react';
import { updateDrawerOpen, useView } from '@/slices/view';
import SwipeableDrawer from '@/components/SwipeableDrawer';
import LeftDrawer from '@/components/LeftDrawer';
import EditingHeader from '@/components/EditingHeader';
import EditingDrawer from '@/components/EditingDrawer';
import { useDispatch } from 'react-redux';
import { useMe } from '@/slices/me';
import { isBrowser } from 'react-device-detect';

export default function Drawer() {
  const dispatch = useDispatch();
  const { drawerOpen } = useView();
  const me = useMe();

  if(isBrowser) {
    return <LeftDrawer header={ EditingHeader } content={ EditingDrawer } />;
  }

  return (
    <SwipeableDrawer
      open={ drawerOpen }
      setOpen={ open => me && dispatch(updateDrawerOpen(open)) }
      header={ EditingHeader }
      content={ EditingDrawer }
    />
  );
}
