import React from 'react';
import { useSelectedBusySpan } from '@/slices/calendar';
import TentativeConflicts from '@/components/TentativeConflicts';

function conflictTextFactory(conflictPeople) {
  const plural = (conflictPeople.length === 1) ? 'person is ' : 'people are ';
  return `${ conflictPeople.length } ${ plural } busy at this time`;
}

export default function People() {
  const busySpan = useSelectedBusySpan();
  return <TentativeConflicts timeSpan={ busySpan.time } textFactory={ conflictTextFactory } />;
}
