import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import moment from '@shared/services/moment';

export const DragAction = {
  NONE: 'none',
  ADD_BUSY_SPAN: 'add_busy_span',
};

export const calendarSlice = createSlice({
  name: 'calendar',

  initialState: {
    selectedBusySpan: null, // The busySpan currently being deleted, or null if none
    date: moment().unix(), // The UNIX-second timestamp of the calendar date being viewed
    dragAction: DragAction.NONE, // What dragging on the calendar will do
  },

  reducers: {
    updateSelectedBusySpan: (state, { payload }) => {
      state.selectedBusySpan = payload;
    },

    updateDate: (state, { payload }) => {
      state.date = payload;
    },

    updateDragAction: (state, { payload }) => {
      state.dragAction = payload;
    },
  },
});

export const {
  updateSelectedBusySpan,
  updateDate,
  updateDragAction,
} = calendarSlice.actions;

// =================================
// Selectors
// =================================
export function useCalendar() {
  return useSelector(state => state.calendar);
}

export function useSelectedBusySpan() {
  return useSelector(state => state.calendar.selectedBusySpan);
}

export function useDate() {
  return useSelector(state => state.calendar.date);
}

export function useDragAction() {
  return useSelector(state => state.calendar.dragAction);
}

export default calendarSlice.reducer;
