import React, { useEffect } from 'react';
import Privacy from '@/components/Privacy';
import { init } from '@/services/analytics';

export default function PrivacyContainer() {
  useEffect(() => {
    init('Privacy Policy');
  }, []);

  return <Privacy />;
}
