import React from 'react';
import PropTypes from 'prop-types';
import styles from './DeleteAnytime.module.scss';
import icon from './icon.png';

function DeleteAnytime({ closeToast }) {
  return (
    <section className={ styles.toast }>
      <img src={ icon } alt='' />

      <p>
        <strong>You can delete your data anytime!</strong> We leave your privacy control in your hands.
      </p>
    </section>
  );
}

DeleteAnytime.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default DeleteAnytime;
