export function landing() {
  return '/';
}
export function meetup(meetupID) {
  return "/".concat(meetupID);
}
export function privacyPolicy() {
  return "".concat(absoluteWeb(), "/privacy");
}
export function withoutProtocol(url) {
  return url.replace('http://', '').replace('https://', '');
}
export function absoluteMeetup(meetupID) {
  return "".concat(absoluteWeb()).concat(meetup(meetupID));
}
export function absoluteWeb() {
  switch (process.env.NODE_ENV) {
    case 'production':
    case 'staging':
      return 'https://calsynk.com';

    case 'development':
      return 'http://localhost:3000';

    default:
      throw new Error("Unknown NODE_ENV ".concat(process.env.NODE_ENV));
  }
}
export function absoluteAPI() {
  switch (process.env.NODE_ENV) {
    case 'production':
    case 'staging':
      return 'https://calsynk.com';

    case 'development':
      return 'http://localhost:8080';

    default:
      throw new Error("Unknown NODE_ENV ".concat(process.env.NODE_ENV));
  }
}