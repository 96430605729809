import React from 'react';
import styles from './Open.module.scss';
import Portrait from '@/components/Portrait';
import ToggleList, { importedCalendarToItem, itemToImportedCalendar } from '@/components/ToggleList';
import Primary from '@/components/Button/Primary';
import Tertiary from '@/components/Button/Tertiary';
import ExtraDrawerActions from './ExtraDrawerActions';
import { useMe, useCalendarColors, updateVisibleImportedCalendars, deleteImportedCalendars, updateForceImport } from '@/slices/me';
import { pushPopup, Popup } from '@/slices/view';
import { useDispatch } from 'react-redux';
import { useAnalytics } from '@/services/analytics';
import { updateDragAction, DragAction, useDragAction } from '@/slices/calendar';
import { isBrowser } from 'react-device-detect';
import { ReactComponent as AddManually } from './add.svg';
import { ReactComponent as AddingManually } from './adding.svg';
import { ReactComponent as DeleteCalendars } from './delete-calendars.svg';
import classnames from 'classnames/bind';

const c = classnames.bind(styles);

export default function Open() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const me = useMe();
  const calendarColors = useCalendarColors();

  const dragAction = useDragAction();
  const addingManually = (dragAction === DragAction.ADD_BUSY_SPAN);

  const calendars = me.importedCalendars.map(importedCalendarToItem);
  function importCalendar() {
    dispatch(updateForceImport(true));
    dispatch(pushPopup(Popup.SIGN_IN));
  }

  if(calendars.length === 0) {
    return <Primary onClick={ importCalendar }>Import Google/Outlook calendar</Primary>;
  }

  const visibleCalendars = me.importedCalendars.filter(calendar => calendar.visible).map(importedCalendarToItem);

  function onToggleVisibleCalendars(visibleCalendars) {
    console.log('new vis', visibleCalendars);
    analytics('change_visible_calendars');

    // TODO: don't always save this immediately, batch everything
    const newVisible = visibleCalendars.map(itemToImportedCalendar).map(calendar => calendar.toPlainObject());
    dispatch(updateVisibleImportedCalendars(newVisible));
  }

  return (
    <div className={ styles.drawer }>
      { !isBrowser && <Portrait person={ me.person } /> }

      <section>
        <header>
          <h1>You're including { visibleCalendars.length } calendars</h1>
          <h2>Only you can see your events</h2>
        </header>

        { !isBrowser && <ExtraDrawerActions /> }
      </section>

      <ToggleList
        items={ calendars }
        itemColors={ calendarColors }
        selectedItems={ visibleCalendars }
        blinkingItems={ [] }
        onChange={ onToggleVisibleCalendars }
      />

      { isBrowser && (
        <>
          <Tertiary
            onClick={ () => dispatch(deleteImportedCalendars()) }
            icon={ <DeleteCalendars /> }
            className={ styles.deleteCalendars }
            key='deleteCalendars'
          >
            Delete all imported calendars
          </Tertiary>

          <Tertiary
            onClick={ () => dispatch(updateDragAction(DragAction.ADD_BUSY_SPAN)) }
            icon={ addingManually ? <AddingManually /> : <AddManually /> }
            className={ c('addManually', { addingManually }) }
            key='addManually'
          >
            Add a busy time manually
          </Tertiary>
        </>
      ) }
    </div>
  );
}
