import React from 'react';
import styles from './InviteDrawer.module.scss';
import { useMeetup } from '@/slices/meetup';
import Primary from '@/components/Button/Primary';
import Secondary from '@/components/Button/Secondary';
import copyToClipboard from '@/services/copy_to_clipboard';
import { absoluteMeetup } from '@shared/services/url';
import copyIcon from './copy.svg';
import { meetupURLCopied } from '@/services/toasts';
import { useAnalytics } from '@/services/analytics';

export default function InviteDrawer() {
  const analytics = useAnalytics();
  const meetup = useMeetup();

  async function invite() {
    analytics('native_share_popup');
    await navigator.share({ title: meetup.name, url: absoluteMeetup(meetup.id) });
  }

  function copyURL() {
    analytics('copy_meetup_url');
    copyToClipboard(absoluteMeetup(meetup.id));
    meetupURLCopied();
  }

  return (
    <div className={ styles.drawer }>
      <header>
        <h1>Invite friends to this meetup</h1>
        <h2>We will visualize the common free time on your calendar to help you meet up</h2>
      </header>

      <section>
        <ul>
          { navigator.share && <li><Primary onClick={ invite }>Invite friends</Primary></li> }
          <li><Secondary onClick={ copyURL } icon={ copyIcon }>Copy meetup URL</Secondary></li>
        </ul>
      </section>
    </div>
  );
}
