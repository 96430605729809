import React, { useState } from 'react';
import styles from './Schedule.module.scss';
import moment from '@shared/services/moment';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import Primary from '@/components/Button/Primary';
import { useDispatch } from 'react-redux';
import { updateSelectedTime } from '@/slices/meetup';
import {
  useScheduling, useTentativeTimeSpan,
  updateTentativeDate, updateTentativeStartTime, updateTentativeEndTime
} from '@/slices/scheduling';
import { replaceCurrentPopup, Popup } from '@/slices/view';
import TentativeConflicts from '@/components/TentativeConflicts';
import { hourSpan } from '@/services/constants';

function conflictTextFactory(conflictPeople) {
  const plural = (conflictPeople.length === 1) ? 'person ' : 'people ';
  return `${ conflictPeople.length } ${ plural } will miss some of the time`;
}

export default function Schedule() {
  const dispatch = useDispatch();
  const timeSpan = useTentativeTimeSpan();
  const { tentativeDate, tentativeStartTime, tentativeEndTime } = useScheduling();
  const [ loading, setLoading ] = useState(false);

  async function schedule() {
    setLoading(true);
    await dispatch(updateSelectedTime(timeSpan));
    setLoading(false);
    dispatch(replaceCurrentPopup(Popup.CONFIRMED));
  }

  return (
    <section className={ styles.container }>
      <header>
        <h1>Schedule a meetup time</h1>
      </header>

      <section>
        <TentativeConflicts timeSpan={ timeSpan } textFactory={ conflictTextFactory } />
      </section>

      <section className={ styles.pickers }>
        <section>
          <label>On</label>

          <DatePicker
            date={ moment.unix(tentativeDate) }
            setDate={ date => dispatch(updateTentativeDate(date.unix())) }
            min={ moment().subtract(1, 'month') }
            max={ moment().add(1, 'month') }
          />
        </section>

        <section>
          <label>From</label>

          <TimePicker
            time={ moment.unix(tentativeStartTime) }
            setTime={ time => dispatch(updateTentativeStartTime(time.unix())) }
            min={ moment({ hour: hourSpan.minHour }) }
            max={ moment({ hour: hourSpan.maxHour }) }
          />
        </section>

        <section>
          <label>To</label>

          <TimePicker
            time={ moment.unix(tentativeEndTime) }
            setTime={ time => dispatch(updateTentativeEndTime(time.unix())) }
            min={ moment({ hour: hourSpan.minHour }) }
            max={ moment({ hour: hourSpan.maxHour }) }
          />
        </section>
      </section>

      <Primary onClick={ schedule } loading={ loading }>Meet at this time</Primary>
    </section>
  );
}
