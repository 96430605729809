// Returns a new array with "value" between all items
//  i.e.    intersperse(["a", "b", "c"], ",")  ->  ["a", ",", "b", ",", "c"]
export function intersperse(array, value) {
  const copy = [];
  array.forEach((element, idx) => {
    if(idx > 0) {
      copy.push(value);
    }

    copy.push(element);
  });
  return copy;
}

// Returns the elements that are only in one of the arrays
//  i.e.    loneElements([1, 2, 3], [2, 3, 4])  ->  [1, 4]
export function loneElements(arrayA, arrayB) {
  const onlyA = reject(arrayA, arrayB.includes.bind(arrayB));
  const onlyB = reject(arrayB, arrayA.includes.bind(arrayA));
  return onlyA.concat(onlyB);
}

// Opposite of .filter
export function reject(array, rejecter) {
  return array.filter(element => !rejecter(element));
}

// Pushes all elements of "elements" into "array"
export function pushAll(array, elements) {
  array.push.apply(array, elements);
}
