// A SequentialPromiseQueue executes Promises 1-by-1 sequentially
export default class SequentialPromiseQueue {
  constructor() {
    this.__queue = [];
  }

  // promiseCreator is a function that takes no arguments and returns a Promise
  add(promiseCreator) {
    this.__queue.push(promiseCreator);

    // This promise is the only one in the queue, lets start it up
    if(this.__queue.length === 1) {
      this.__pop();
    }
  }

  async __pop() {
    // Execute the first promise in the queue
    await this.__queue[0]();
    this.__queue.shift();

    // Complete other promises in the queue
    if(this.__queue.length > 0) {
      this.__pop();
    }
  }
}
