import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './HowItWorks.module.scss';
import Logo from '@/components/Logo';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import Div100vh from 'react-div-100vh';
import classnames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import CreateMeetup from '@/components/Button/CreateMeetup';
import OutsideClickHandler from 'react-outside-click-handler';
import invite from './invite.gif';
import check from './check.gif';
import schedule from './schedule.gif';

const c = classnames.bind(styles);

const Steps = [
  { shortTitle: 'Invite', title: 'Invite others', image: invite, body: (
    <p>
      After you start the group calendar, your calendars will display in different blues on the calendar.
      Invite other people so they can add their calendars to the group calendar.
    </p>
  ) },

  { shortTitle: 'Check', title: 'Check', image: check, body: (
    <section>
      <p>
        When multiple people have joined, you'll see different kinds of times slotted out on the group calendar.
      </p>

      <section className={ c('time', 'ownTime') }>
        <div />
        <div>
          <h2>Your time</h2>
          <p>These are events from your calendars. They signify that you're busy and appear grey to others. Only you can see the names of your events.</p>
        </div>
      </section>

      <section className={ c('time', 'singleTime') }>
        <div />
        <div>
          <h2>Other's time</h2>
          <p>These are events from other people's calendar. They signify that a single person is busy at this time.</p>
        </div>
      </section>

      <section className={ c('time', 'multiTime') }>
        <div />
        <div>
          <h2>High traffic time</h2>
          <p>High traffic times are not good times to meet up. Multiple people are busy at this time.</p>
        </div>
      </section>
    </section>
  ) },

  { shortTitle: 'Schedule', title: 'Schedule', image: schedule, body: (
    <section>
      <p>
        An empty area on the group calendar means that everyone is free at that time.
        Either drag that time on the group calendar or use the "Schedule" button to lock-in the meetup time.
      </p>

      <div className={ styles.create }><CreateMeetup /></div>
    </section>
  ) },
];

function HowItWorks({ onRequestClose }) {
  const [ step, setStep ] = useState(0); // 0 <= step < Steps.length
  const Wrapper = isMobile ? Div100vh : 'div';

  const nav = (
    <nav>
      <ol>
        { Steps.map(({ shortTitle}, idx) =>
          <li key={ shortTitle } className={ step === idx && styles.selected }>
            <div>{ idx + 1 }</div>
            <div>{ shortTitle }</div>
          </li>
        ) }
      </ol>
    </nav>
  );

  const containerStyle = {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: isMobile ? '100rvh' : '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: !isMobile && 'rgba(0, 0, 0, 0.3)',
  };
  return (
    <Wrapper style={ containerStyle }>
      <div className={ styles.howItWorks }>
        <OutsideClickHandler onOutsideClick={ onRequestClose }>
          <div className={ styles.inner }>
            <header>
              <Logo />
              { !isMobile && nav }
              <Close onClick={ onRequestClose } className={ styles.close } />
            </header>

            { isMobile && nav }

            <section className={ styles.main }>
              <section className={ styles.image }>
                <button onClick={ () => setStep(step - 1) } className={ c('left', { hidden: step === 0 }) }><ArrowLeft /></button>
                <img alt={ Steps[step].title } src={ Steps[step].image } />
                <button onClick={ () => setStep(step + 1) } className={ c('right', { hidden: step === Steps.length - 1 }) }><ArrowRight /></button>
              </section>

              <section className={ styles.description }>
                <h1>{ Steps[step].title }</h1>
                { Steps[step].body }
              </section>
            </section>
          </div>
        </OutsideClickHandler>
      </div>
    </Wrapper>
  );
}

HowItWorks.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default HowItWorks;
