import React from 'react';
import PropTypes from 'prop-types';
import styles from './PortraitSet.module.scss';
import Portrait from '@/components/Portrait';

function PortraitSet({ people, max, size }) {
  function personToPortrait(person, idx) {
    const style = {
      marginLeft: -(idx * (size / 2)),
      zIndex: max - idx,
    };

    return (
      <li key={ person.id } style={ style }>
        <Portrait person={ person } size={ size } />
      </li>
    );
  }

  return (
    <ul className={ styles.set }>
      { people.slice(0, max).map(personToPortrait) }
    </ul>
  );
}

PortraitSet.propTypes = {
  people: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		photo: PropTypes.string,
	})).isRequired,
  size: PropTypes.number,
  max: PropTypes.number.isRequired, // Max # of portraits to show
};

PortraitSet.defaultProps = {
  size: 28,
};

export default PortraitSet;
