import React from 'react';
import classnames from 'classnames/bind';
import styles from './Event.module.scss';
import { useMe, useCalendarColors } from '@/slices/me';
import { useTentativeTimeSpan } from '@/slices/scheduling';
import { pushPopup, Popup } from '@/slices/view';
import { updateSelectedBusySpan, useSelectedBusySpan } from '@/slices/calendar';
import { useDispatch } from 'react-redux';
import Meetup from '@shared/models/meetup';
import { useMeetup } from '@/slices/meetup';
import moment from '@shared/services/moment';

const c = classnames.bind(styles);

export default function Event({ event: busySpan, title }) {
  const dispatch = useDispatch();
  const me = useMe();
  const calendarColors = useCalendarColors();
  const tentativeTimeSpan = useTentativeTimeSpan();
  const selectedBusySpan = useSelectedBusySpan();
  const meetup = Meetup.fromPlainObject(useMeetup());
  const userIsOwner = me && busySpan.person && busySpan.person.id === me.person.id;

  function select() {
    dispatch(updateSelectedBusySpan(busySpan));
    dispatch(pushPopup(Popup.BUSY_SPAN_DETAILS));
  }

  const style = {};
  if(userIsOwner) {
    const { calendarID } = me.busySpanCalendarEvents[busySpan.id] || {};
    if(calendarID) {
      style.backgroundColor = calendarColors.get(calendarID);
    }
  }

  const isTentative = tentativeTimeSpan && busySpan.time.start === tentativeTimeSpan.start && busySpan.time.end === tentativeTimeSpan.end;
  const peopleBusy = meetup.getBusyPeopleAtMoment(moment.unix(busySpan.time.start));
  const classes = c(
    'event',
    {
      selected: busySpan === selectedBusySpan,
      tentative: isTentative,
      singlePersonBusy: peopleBusy.size === 1,
      manyPeopleBusy: peopleBusy.size > 1,
    }
  );

  // Figure out which title to display
  let computedTitle = '';
  if(isTentative) {
    computedTitle = `${ moment.unix(busySpan.time.start).format('hh:mm A') } - ${ moment.unix(busySpan.time.end).format('hh:mm A') }`;
  } else if(me && busySpan.person && busySpan.person.id === me.person.id) {
    computedTitle = title;
  } else if(peopleBusy.size === 1) {
    computedTitle = `${ [ ...peopleBusy ][0].name } is busy`;
  } else if(peopleBusy.size > 1) {
    computedTitle = 'multiple people are busy';
  }

  return (
    <div
      onClick={ select }
      className={ classes }
      style={ style }
    >
      <div className={ styles.title }>
        { computedTitle }
      </div>
    </div>
  );
}
