import React from 'react';
import styles from './Confirmed.module.scss';
import { google } from '@shared/services/add_to_calendar';
import Secondary from '@/components/Button/Secondary';
import Tertiary from '@/components/Button/Tertiary';
import googleCalendarIcon from './google-calendar.svg';
import { useMeetup } from '@/slices/meetup';
import { useDispatch } from 'react-redux';
import { pushPopup, Popup } from '@/slices/view';
import TimeSpan from '@shared/models/time_span';
import { useAnalytics } from '@/services/analytics';

export default function Confirmed() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const meetup = useMeetup();
  const selectedTime = TimeSpan.fromPlainObject(meetup.selectedTime);
  const { start, end } = selectedTime;

  function addToCalendarURL() {
    return google({
      title: `${ meetup.name } (Calsynk)`,
      timeSpan: selectedTime,
    });
  }

  function cancel() {
    dispatch(pushPopup(Popup.CANCEL));
    analytics('cancel_meetup_time');
  }

  return (
    <section className={ styles.confirmed }>
      <header>
        <h1>Meetup confirmed!</h1>
        <h2>Everyone in this meetup has been notified</h2>
      </header>

      <section className={ styles.details }>
        <section>
          <span className={ styles.time }>{ start.format('hh:mm') }</span>
          <span className={ styles.amPM }>{ start.format('A') }</span>
          <hr />
          <span className={ styles.time }>{ end.format('hh:mm') }</span>
          <span className={ styles.amPM }>{ end.format('A') }</span>
        </section>

        <div className={ styles.name }>{ meetup.name }</div>
        <div className={ styles.date }>{ start.format('dddd MMMM DD') } - { start.fromNow() }</div>
      </section>

      <section>
        <a href={ addToCalendarURL() } target='_blank' rel='noopener noreferrer'>
          <Secondary onClick={ () => analytics('add_to_calendar') } icon={ googleCalendarIcon } className={ styles.add }>
            Add to my calendar
          </Secondary>
        </a>

        <Tertiary onClick={ cancel } className={ styles.cancel }>
          Cancel this meetup time
        </Tertiary>
      </section>
    </section>
  );
}
