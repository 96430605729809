import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from '@shared/services/moment';
import HourSpan from '@shared/models/hour_span';
import './Calendar.scss'; // This is used to style react-big-calendar, because it needs global styles
import styles from './Calendar.module.scss';
import Toolbar from '@/components/Calendar/Toolbar';
import EventContainerWrapper from '@/components/Calendar/EventContainerWrapper';
import Header from '@/components/Calendar/Header';
import MobileView from '@/components/Calendar/MobileView';
import { useDate, updateDate, useDragAction, DragAction } from '@/slices/calendar';
import { useDispatch } from 'react-redux';
import { numMobileCalendarDays } from '@/services/constants';
import { Swipeable } from 'react-swipeable';
import { useAnalytics } from '@/services/analytics';
import { isBrowser } from 'react-device-detect';

const formats = {
	timeGutterFormat: 'h A',
	dayFormat: 'ddd[\n]D',
	dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
		return localizer.format(start, 'MMMM Y', culture);
	},
};

const Calendar = React.memo(function Calendar({ busySpans, hourSpan, selectable, onTryCreate, onSelecting, eventStyle, eventComponent }) {
	const date = useDate();
	const dispatch = useDispatch();
	const analytics = useAnalytics();
	const dragAction = useDragAction();

	const onSelectSlot = useCallback(data => {
		if(data.action !== 'select') {
			return;
		}

		return onTryCreate(data);
	}, [ onTryCreate ]);

	const onSelectingRaw = useCallback(({ start, end }) => {
		if(!selectable) {
			return false;
		}

		return onSelecting({ start, end });
	}, [ onSelecting, selectable ]);

	const onSwipe = useCallback(({ dir }) => {
		if(isBrowser) {
			return;
		}

		const diff = moment.duration(numMobileCalendarDays, 'days').asSeconds();
		if(dir === 'Left') {
			dispatch(updateDate(date + diff));
			analytics('calendar_next');
		} else if(dir === 'Right') {
			dispatch(updateDate(date - diff));
			analytics('calendar_prev');
		}
	}, [ date, dispatch, analytics ]);

	const components = {
		event: eventComponent,
		eventContainerWrapper: EventContainerWrapper,
		toolbar: Toolbar,
		week: {
			header: Header,
		},
		mobile: {
			header: Header,
		},
	};

	let className = styles.calendar;
	if(dragAction === DragAction.ADD_BUSY_SPAN) {
		className += ' ReactCalendar-addingManually';
	}


	// react-big-calendar has issues displaying events that span the whole day
	// This makes all-day events shorter by up to 2 seconds
	const fixedBusySpans = busySpans.map(busySpan => {
		const copy = {
			...busySpan,
			time: { ...busySpan.time },
		};

		// Fix the start time
		const startMoment = moment.unix(busySpan.time.start);
		if(startMoment.isSame(startMoment.startOf('day'), 'second')) {
			copy.time.start++;
		}

		// Fix the end time
		const endMoment = moment.unix(busySpan.time.end);
		if(endMoment.isSame(endMoment.endOf('day'), 'second')) {
			copy.time.end--;
		}

		return copy;
	});

	return (
		<div className={ className }>
			<Swipeable onSwiped={ onSwipe }>
				<BigCalendar
					selectable={ true }
					localizer={ momentLocalizer(moment) }
					events={ fixedBusySpans }
					min={ hourSpan.getMinHourAsDate() }
					max={ hourSpan.getMaxHourAsDate() }
					titleAccessor={ busySpan => busySpan.name || '' }
					startAccessor={ busySpan => moment.unix(busySpan.time.start).toDate() }
					endAccessor={ busySpan => moment.unix(busySpan.time.end).toDate() }
					allDayAccessor={ busySpan => false }
					formats={ formats }
					defaultView='mobile'
					views={{ mobile: MobileView }}
					onSelectSlot={ onSelectSlot }
					eventPropGetter={ eventStyle }
					components={ components }
					step={ 30 }
					longPressThreshold={ 100 }
					date={ new Date(date * 1000) }
					onNavigate={ () => {} }
					showMultiDayTimes
					onSelecting={ onSelectingRaw }
				/>
			</Swipeable>
		</div>
	);
});

Calendar.propTypes = {
	busySpans: PropTypes.arrayOf(PropTypes.object).isRequired,
	hourSpan: PropTypes.instanceOf(HourSpan).isRequired,
	onTryCreate: PropTypes.func,
	selectable: PropTypes.bool,
	eventStyle: PropTypes.func,
	eventComponent: PropTypes.elementType.isRequired,
	onSelecting: PropTypes.func,
};

Calendar.defaultProps = {
	selectable: false,
	onSelecting: () => {},
};

export default Calendar;
