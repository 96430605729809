import httpStatus from 'http-status-codes';
import APIResponse from '@/models/api_response';

export default class EmailSignInResponse extends APIResponse {
  isValid() {
    return super.isValid() || this.needsName() || this.pendingDeviceAuth();
  }

  needsName() {
    return this.getStatus() === httpStatus.RESET_CONTENT;
  }

  pendingDeviceAuth() {
    return this.getStatus() === httpStatus.PARTIAL_CONTENT;
  }
}
