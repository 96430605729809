import React from 'react';
import PropTypes from 'prop-types';
import styles from './CalendarsImported.module.scss';
import { ReactComponent as Icon } from './icon.svg';

function CalendarsImported({ closeToast }) {
  return (
    <section className={ styles.toast }>
      <Icon />

      <p>
        <strong>Your calendars were imported!</strong> Your friends will see when you're busy but not what you're doing.
      </p>
    </section>
  );
}

CalendarsImported.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default CalendarsImported;
