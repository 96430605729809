import React from 'react';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';

function MobileDiv100vh({ children }) {
  if(isMobile) {
    return <Div100vh>{ children }</Div100vh>;
  }

  return children;
}

MobileDiv100vh.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MobileDiv100vh;
