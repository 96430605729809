import React from 'react';
import styles from './EditingHeader.module.scss';
import { pushPopup, Popup } from '@/slices/view';
import { useDispatch } from 'react-redux';
import Drawer from '@/components/Button/Drawer';
import { useMe } from '@/slices/me';
import { useCanSchedule } from '@/slices/meetup';
import { ReactComponent as GraytoneLogo } from './logo.svg';
import { beginScheduling } from '@/slices/scheduling';
import moment from '@shared/services/moment';
import { isBrowser } from 'react-device-detect';

export default function EditingHeader() {
  const dispatch = useDispatch();
  const canSchedule = useCanSchedule();
  const me = useMe();

  return (
    <header className={ styles.container }>
      { (me && !isBrowser) && <section className={ styles.swiper } /> }

      <section className={ styles.header }>
        <div>
          <GraytoneLogo />
          <h1>Group calendar</h1>
        </div>

        { (!isBrowser && !canSchedule) && <Drawer onClick={ () => dispatch(pushPopup(Popup.INVITE)) }>Invite</Drawer> }
        { (!isBrowser && canSchedule) && <Drawer onClick={ () => dispatch(beginScheduling(moment().unix())) }>Schedule</Drawer> }
      </section>
    </header>
  );
}
