import React from 'react';
import { toast } from 'react-toastify';
import MeetupURLCopied from '@/components/Toast/MeetupURLCopied';
import InvitationComplete from '@/components/Toast/InvitationComplete';
import CalendarsImported from '@/components/Toast/CalendarsImported';
import NewPeople from '@/components/Toast/NewPeople';
import CantSchedule from '@/components/Toast/CantSchedule';
import DeleteAnytime from '@/components/Toast/DeleteAnytime';
import { isBrowser } from 'react-device-detect';

export function calendarsImported() {
  toast(CalendarsImported, { containerId: 'belowOverview', autoClose: 5000 });
}

export function meetupURLCopied() {
  toast(MeetupURLCopied, { containerId: 'top', autoClose: 3000 });
}

export function invitationComplete() {
  toast(InvitationComplete, { containerId: 'top', autoClose: 3000 });
}

export function cantSchedule() {
  toast(CantSchedule, { containerId: 'belowOverview', autoClose: 3000 });
}

export function deleteAnytime() {
  if(!isBrowser) {
    return;
  }

  toast(DeleteAnytime, { containerId: 'rightOfLeftBar', autoClose: 3000 });
}

export function newPeopleJoined() {
  const containerId = isBrowser ? 'belowOverview' : 'aboveDrawer';

  // Need the arrow function otherwise hooks don't work inside NewPeople
  toast(({ closeToast }) => <NewPeople closeToast={ closeToast } />, { containerId, autoClose: 5000 });
}
