import React from 'react';
import styles from './Participants.module.scss';
import Portrait from '@/components/Portrait';
import { useMeetup } from '@/slices/meetup';
import Icon from '@/components/Button/Icon';
import { ReactComponent as Add } from './add.svg';
import { useDispatch } from 'react-redux';
import { pushPopup, Popup } from '@/slices/view';

function personToPortrait(person) {
  return (
    <div key={ person.id }>
      <Portrait size={ 36 } person={ person } />
    </div>
  );
}

export default function Participants() {
  const dispatch = useDispatch();
  const { people } = useMeetup();

  return (
    <section className={ styles.participants }>
      { people.map(personToPortrait) }

      <div key='add'>
        <Icon
          icon={ <Add /> }
          className={ styles.add }
          onClick={ () => dispatch(pushPopup(Popup.INVITE)) }
        />
      </div>
    </section>
  );
}
