import { useState, useEffect, useRef } from 'react';
import { useView, Popup, AppScreen, pushPopup, useCurrentPopup } from '@/slices/view';
import { calendarsImported, invitationComplete, newPeopleJoined, deleteAnytime } from '@/services/toasts';
import { useMeetup } from '@/slices/meetup';
import { useHistory } from 'react-router-dom';
import { useMe } from '@/slices/me';
import { updateSelectedBusySpan } from '@/slices/calendar';
import { useDispatch } from 'react-redux';
import { useAnalytics } from '@/services/analytics';
import store from 'store';
import { isBrowser } from 'react-device-detect';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function ViewWatcher() {
  const [ processedView, setProcessedView ] = useState(null);
  const [ deleteNoticeShown, setDeleteNoticeShown ] = useState(false);
  const view = useView();
  const prevView = usePrevious(view);
  const history = useHistory();
  const currentPopup = useCurrentPopup();
  const meetup = useMeetup();
  const me = useMe();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  // React to changes in the view
  useEffect(() => {
    if(view === processedView) {
      return;
    }

    analytics('screen_view', { screen_name: view.screen });

    if(!me) {
      return;
    }

    const { screen, popups } = view;
    const { popups: prevPopups } = prevView || { popups: [] };
    const prevPopup = prevPopups.length === 0 ? null : prevPopups[prevPopups.length - 1];

    if(screen === AppScreen.MAIN) {
      if(popups.length === 0) {
        // Show a toast after closing the invite popup (or the creating popup for desktop)
        if(prevPopup === Popup.INVITE || (prevPopup === Popup.CREATING && isBrowser)) {
          const justCreated = history.location.state && history.location.state.justCreated;
          if(justCreated) {
            history.replace({ state: {} });
            calendarsImported();
          } else {
            invitationComplete();
          }
        }

        // Show a toast about the new people who joined since this user was last on the page
        const peopleSeen = store.get('v1_people_seen') || {};
        peopleSeen[meetup.id] = peopleSeen[meetup.id] || [];

        const hasSeenSelf = peopleSeen[meetup.id].some(person => person.id === me.person.id);
        if(hasSeenSelf && meetup.people.length > peopleSeen[meetup.id].length) {
          newPeopleJoined();
        }

        peopleSeen[meetup.id] = meetup.people;
        store.set('v1_people_seen', peopleSeen);

        // Clear the selected busy span once the details popup is closed
        if(prevPopup === Popup.BUSY_SPAN_DETAILS && popups.length === 0) {
          dispatch(updateSelectedBusySpan(null));
        }

        // Show the meetup confirmation if there is one
        if(meetup.selectedTime !== null) {
          dispatch(pushPopup(Popup.CONFIRMED));
        }
      }

      if(currentPopup === Popup.CONFIRMED && !deleteNoticeShown) {
        deleteAnytime();
        setDeleteNoticeShown(true);
      }
    }

    // Don't process this view again
    setProcessedView(view);
  }, [ dispatch, view, prevView, history, meetup, me, processedView, analytics, deleteNoticeShown, currentPopup ]);

  // This component is only intended to listen to state changes and perform actions
  return null;
}
