// Copies the specified text to the clipboard
// This can only happen after a user action (i.e. a click)
export default text => {
	// Create an element to copy text from
	const el = document.createElement('textarea');
	el.value = text;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);

	// Select the text and copy it
	el.select();
	document.execCommand('copy');

	// Clean up the element
	document.body.removeChild(el);
}
