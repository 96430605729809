import React, { useState } from 'react';
import classnames from 'classnames'
import styles from './CreateMeetup.module.scss';
import MeetupAPI from '@/services/meetup_api';
import { meetup } from '@shared/services/url';
import { withRouter } from 'react-router-dom';
import Primary from '@/components/Button/Primary';

const CreateMeetup = withRouter(function CreateMeetup({ className, history }) {
  const [ creating, setCreating ] = useState(false);

  async function createMeetup() {
    setCreating(true);

    const meetupID = await MeetupAPI.createMeetup('Untitled Meetup');
    history.push(meetup(meetupID), { justCreated: true });
  }

  return (
    <Primary
      loading={ creating }
      onClick={ createMeetup }
      className={ classnames(styles.create, className) }
    >
      Start a new meetup
    </Primary>
  );
});

export default CreateMeetup;
