import React, { useEffect } from 'react';
import Landing from '@/components/Landing';
import { init } from '@/services/analytics';

export default function LandingContainer() {
  useEffect(() => {
    init('Landing');
  }, []);

  return <Landing />;
}
