import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './Button.module.scss';

const c = classnames.bind(styles);

function Button({ children, onClick, className, icon, disabled, loading }) {
	function renderIcon() {
		if(!icon) {
			return null;
		}

		if(typeof icon === 'string') {
			return <img src={ icon } alt={ children } className={ styles.icon } />;
		}

		return (
			<span className={ styles.icon }>
				{ icon }
			</span>
		);
	}

	function inner() {
		if(loading) {
			return (
				<div className={ styles.loader }>
					<div></div><div></div><div></div><div></div>
				</div>
			);
		}

		return (
			<>
				{ renderIcon() }
				<span>{ children }</span>
			</>
		)
	}

	return (
		<button onClick={ (!disabled && !loading) ? onClick : undefined } className={ c('button', className) }>
			{ inner() }
		</button>
	)
};

Button.propTypes = {
	children: PropTypes.any,
  onClick: PropTypes.func,
	className: PropTypes.string,
	icon: PropTypes.any,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
	className: '',
	loading: false,
};

export default Button;
