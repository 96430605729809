import React, { useContext } from 'react';
import classnames from 'classnames/bind';
import styles from './Item.module.scss';
import ToggleListContext from '@/services/contexts/toggle_list';
import { ReactComponent as CheckIcon } from './check.svg';

const c = classnames.bind(styles);

export default function Item({ item: { id, label, color }, onClick, checked }) {
  const { blinkingItems } = useContext(ToggleListContext);
  const blinking = blinkingItems.includes(id);

  const checkStyle = {};
  const labelStyle = {};

  if(color && checked) {
    checkStyle.background = color;
    labelStyle.color = color;
  }

  return (
    <div onClick={ onClick } className={ c('container', { checked, blinking }) }>
      <div className={ styles.check } style={ checkStyle }>
        { checked && <CheckIcon title='' className={ styles.icon } /> }
      </div>

      <div className={ styles.label } style={ labelStyle }>
        { label }
      </div>
    </div>
  );
}
