import httpStatus from 'http-status-codes';
import APIResponse from '@/models/api_response';

export default class AllowDeviceResponse extends APIResponse {
  isValid() {
    return super.isValid() || this.invalidSecret();
  }

  invalidSecret() {
    return this.getStatus() === httpStatus.UNAUTHORIZED;
  }
}
