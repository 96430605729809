import React from 'react';
import styles from './ReactToastifyContainer.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './ReactToastifyContainer.scss';

export default function ReactToastifyContainer() {
  return (
    <>
      <ToastContainer
        key='top'
        containerId='top'
        enableMultiContainer
        className={ styles.top }
        bodyClassName={ styles.topBody }
        hideProgressBar={ true }
        closeButton={ false }
        position={ toast.POSITION.TOP_LEFT }
      />

      <ToastContainer
        key='belowOverview'
        containerId='belowOverview'
        enableMultiContainer
        className={ styles.belowOverview }
        bodyClassName={ styles.belowOverviewBody }
        hideProgressBar={ true }
        closeButton={ false }
        position={ toast.POSITION.TOP_LEFT }
      />

      <ToastContainer
        key='aboveDrawer'
        containerId='aboveDrawer'
        enableMultiContainer
        className={ styles.aboveDrawer }
        bodyClassName={ styles.aboveDrawerBody }
        hideProgressBar={ true }
        closeButton={ false }
        position={ toast.POSITION.BOTTOM_LEFT }
      />

      <ToastContainer
        key='rightOfLeftBar'
        containerId='rightOfLeftBar'
        enableMultiContainer
        className={ styles.rightOfLeftBar }
        bodyClassName={ styles.rightOfLeftBarBody }
        hideProgressBar={ true }
        closeButton={ false }
        position={ toast.POSITION.TOP_LEFT }
      />
    </>
  );
}
