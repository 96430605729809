import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Meetup from './Meetup';
import { useDispatch } from 'react-redux';
import { fetchMeetup } from '@/slices/meetup';
import { updateScreen, pushPopup, Popup, AppScreen } from '@/slices/view';
import { fetchMe } from '@/slices/me';
import store from 'store';
import { init } from '@/services/analytics';
import { isBrowser } from 'react-device-detect';

export default function MeetupContainer() {
  const { meetupID } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [ initialEffectDone, setInitialEffectDone ] = useState(false);

  // Initialize analytics
  useEffect(() => init('Meetup'), []);

  // Fetch initial data and setup the initial view
  useEffect(() => {
    if(initialEffectDone) {
      return;
    }

    function setInitialScreen() {
      const screen = (store.get('splash_v1_seen') || isBrowser) ? AppScreen.MAIN : AppScreen.SPLASH;
      const justCreated = history.location.state && history.location.state.justCreated;
      if(justCreated) {
        dispatch(pushPopup(Popup.SIGN_IN));
      }

      dispatch(updateScreen(screen));
    }

    function fetchInitialData() {
      return Promise.all([
        dispatch(fetchMeetup(meetupID)),
        dispatch(fetchMe(meetupID)),
      ]);
    }

    setInitialEffectDone(true);
    // TODO: if the meetup has a selectedTime, should we just show them Popup.CONFIRMED?
    fetchInitialData().then(setInitialScreen);
  }, [ meetupID, dispatch, history, initialEffectDone ]);

  return <Meetup />;
}
