import React from 'react';
import styles from './Popup.module.scss';
import { useCurrentPopup, popPopup, Popup } from '@/slices/view';
import CreatingMeta from '@/components/CreatingMeta';
import InviteDrawer from '@/components/InviteDrawer';
import ClosePopup from '@/components/Button/ClosePopup';
import SignIn from '@/components/SignIn';
import Schedule from '@/components/Schedule';
import Confirmed from '@/components/Confirmed';
import Cancel from '@/components/Cancel';
import BusySpanDetails from '@/components/BusySpanDetails';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

export default function PopupContainer() {
  const dispatch = useDispatch();
  const popup = useCurrentPopup();

  // Determine which popup to show
  if(!popup) {
    return null;
  }

  let content;
  switch(popup) {
    case Popup.CREATING: content = <CreatingMeta />; break;
    case Popup.INVITE: content = <InviteDrawer />; break;
    case Popup.SIGN_IN: content = <SignIn />; break;
    case Popup.SCHEDULE: content = <Schedule />; break;
    case Popup.CONFIRMED: content = <Confirmed />; break;
    case Popup.CANCEL: content = <Cancel />; break;
    case Popup.BUSY_SPAN_DETAILS: content = <BusySpanDetails />; break;
    default: throw new Error(`Unknown popup ${ popup }`);
  }

  // TODO: disable certain popups from being closed
  return (
    <ReactModal
      isOpen={ true }
      overlayClassName={ styles.mask }
      className={ styles.popup }
      onRequestClose={ () => dispatch(popPopup()) }
    >
      <section className={ styles.inner }>
        { content }

        <ClosePopup className={ styles.close } onClick={ () => dispatch(popPopup()) }>X</ClosePopup>
      </section>
    </ReactModal>
  );
}
