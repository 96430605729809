import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './NotFound.module.scss';

export default function NotFound() {
  const history = useHistory();

  return (
    <div className={ styles.container }>
      <div className={ styles.inner }>
        <h1>Sorry about that :(</h1>
        <h2>We couldn't find what you were looking for.</h2>

        <main className={ styles.actions }>
          <a href='/'>Go to Homepage</a>
          <button onClick={ () => history.goBack() }>Try Again</button>
        </main>
      </div>
    </div>
  );
}
