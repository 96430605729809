import TimeSpan from '@shared/models/time_span';
import { uniq, comparator, max, min } from '@shared/services/moment';
import flatten from 'lodash.flatten';
import BusySpan from '@shared/models/busy_span';

export default class TimeSpanContainer {
  constructor(timeSpans) {
    this.timeSpans = timeSpans.slice();
  }

  static fromBusySpans(busySpans) {
    return new TimeSpanContainer(busySpans.map(busySpan => busySpan.time));
  }

  toBusySpans() {
    return this.timeSpans.map(BusySpan.fromTimeSpan, BusySpan);
  }

  // Clones this TimeSpanContainer
  // NOTE: this is only a shallow clone
  clone() {
    return new TimeSpanContainer(this.timeSpans.slice());
  }

  // Returns a set of minimal TimeSpans that cover the time covered by the set of TimeSpans
  minimalSpans() {
		if(this.timeSpans.length === 0) {
      return [];
    }

    // Create a copy so we don't mutate the original array when sorting.
    // We won't mutate any TimeSpan objects, so a shallow copy is enough.
    const timeSpans = [ ...this.timeSpans ];
		timeSpans.sort(TimeSpan.earliestStartTimeComparator);

		// Iterate over the sorted timeSpans and merge where possible
    const mergedTimeSpans = [ timeSpans[0] ]; // Never fails because of the invariant timeSpans.length > 0

    for(let i = 0; i < timeSpans.length; i++) {
      const curMerging = mergedTimeSpans.length - 1; // Never fails because of the invariant mergedTimeSpans.length > 0

      if(timeSpans[i].intersects(mergedTimeSpans[curMerging])) {
        mergedTimeSpans[curMerging] = timeSpans[i].merge(mergedTimeSpans[curMerging]);
      } else {
        mergedTimeSpans.push(timeSpans[i]);
      }
		}

		return mergedTimeSpans;
  }

  // Returns all moments when TimeSpans in this container start or end
  // and moments when a new day starts
  boundaries() {
    if(this.timeSpans.length === 0) {
      return [];
    }

    const bounds = [];
    this.timeSpans.forEach(({ start, end }) => {
      bounds.push(start);
      bounds.push(end);
    });

    // Add the day starts
    // NOTE: this could be very slow if the date range is big
    let curMoment = bounds.reduce(min).clone().startOf('day');
    const maxMoment = bounds.reduce(max).clone();

    while(!curMoment.isSame(maxMoment, 'day')) {
      curMoment.add(1, 'day');
      bounds.push(curMoment.clone());
    }

    return uniq(bounds);
  }

  // Returns a list of TimeSpans disjoint by whether crossing a boundary results in a difference,
  // where the caller defines whether that difference exists
  //
  // i.e.
  //    if areBoundariesDifferent = false if both boundaries have the same number of intersecting timeSpans
  //    then this maps (1, 5) (2, 3) --> (1, 2) (2, 3), (3, 5)
  disjointByBoundaryDifference(areBoundariesDifferent) {
    const bounds = this.boundaries();
    bounds.sort(comparator);

    if(bounds.length === 0) {
      return new TimeSpanContainer([]);
    }

    let startBound = bounds[0];
    const disjointTimeSpans = [];
    for(let i = 0; i < bounds.length - 1; i++) {
      if(!areBoundariesDifferent(bounds[i], bounds[i + 1])) {
        continue;
      }

      const timeSpan = TimeSpan.fromPlainObject({
        start: startBound.unix(),
        end: bounds[i + 1].unix(),
      });
      disjointTimeSpans.push(timeSpan);
      startBound = bounds[i + 1];
    }
    return new TimeSpanContainer(disjointTimeSpans);
  }

  minBoundary() {
    return this.boundaries().reduce(min);
  }

  maxBoundary() {
    return this.boundaries().reduce(max);
  }

  isEmpty() {
    return this.timeSpans.length === 0;
  }

  // Returns a new TimeSpanContainer with TimeSpans added to or removed from the start such that minBoundary() === startMoment
  // Returns a clone of the current TimeSpanContainer if it's empty
  withStart(startMoment) {
    const clone = this.clone();
    if(this.isEmpty()) {
      return clone;
    }

    if(this.minBoundary().isAfter(startMoment)) {
      clone.timeSpans.push(TimeSpan.fromPlainObject({
        start: startMoment.unix(),
        end: this.minBoundary().unix(),
      }));
    }

    if(this.minBoundary().isBefore(startMoment)) {
      clone.timeSpans = clone.timeSpans
        .filter(timeSpan => timeSpan.end.isAfter(startMoment))
        .map(timeSpan => timeSpan.withStart(max(startMoment, timeSpan.start)));
    }

    return clone;
  }

  // Returns a new TimeSpanContainer with TimeSpans added to or removed from the end such that maxBoundary() === endMoment
  // Returns a clone of the current TimeSpanContainer if it's empty
  withEnd(endMoment) {
    const clone = this.clone();
    if(this.isEmpty()) {
      return clone;
    }

    if(this.maxBoundary().isBefore(endMoment)) {
      clone.timeSpans.push(TimeSpan.fromPlainObject({
        start: this.maxBoundary().unix(),
        end: endMoment.unix(),
      }));
    }

    if(this.maxBoundary().isAfter(endMoment)) {
      clone.timeSpans = clone.timeSpans
        .filter(timeSpan => timeSpan.start.isBefore(endMoment))
        .map(timeSpan => timeSpan.withEnd(min(endMoment, timeSpan.end)));
    }

    return clone;
  }

  // Returns a copy of this TimeSpanContainer with the given timeSpan empty
  withoutTimeRange(excludeTimeSpan) {
    const clone = this.clone();

    clone.timeSpans = flatten(clone
      .timeSpans
      .filter(timeSpan => !excludeTimeSpan.containsTimeSpanInclusive(timeSpan))
      .map(timeSpan => {
        if(!excludeTimeSpan.intersects(timeSpan)) {
          // timeSpan is not inside the exclusion range
          return timeSpan;
        }

        if(timeSpan.containsTimeSpanInclusive(excludeTimeSpan)) {
          const earlierTimeSpan = TimeSpan.fromPlainObject({
            start: timeSpan.start.unix(),
            end: excludeTimeSpan.start.unix(),
          });

          const laterTimeSpan = TimeSpan.fromPlainObject({
            start: excludeTimeSpan.end.unix(),
            end: timeSpan.end.unix(),
          });

          return [ earlierTimeSpan, laterTimeSpan ];
        }

        // timeSpan is partially contained in the exclusion range
        if(excludeTimeSpan.containsMoment(timeSpan.start)) {
          return timeSpan.withStart(excludeTimeSpan.end);
        }

        if(excludeTimeSpan.containsMoment(timeSpan.end)) {
          return timeSpan.withEnd(excludeTimeSpan.start);
        }

        throw new Error('We missed a case in withoutTimeRange');
      }));

    return clone;
  }

  concat(timeSpanContainer) {
    const clone = this.clone();
    timeSpanContainer.timeSpans.forEach(timeSpan => clone.timeSpans.push(timeSpan));
    return clone;
  }

  filter(shouldKeep) {
    return new TimeSpanContainer(this.timeSpans.filter(shouldKeep));
  }

  // Opposite of filter
  reject(shouldReject) {
    return this.filter(timeSpan => !shouldReject(timeSpan));
  }
}
