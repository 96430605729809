import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from './Landing.module.scss';
import Logo from '@/components/Logo';
import background from './background.svg';
import backgroundMobile from './background-mobile.svg';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';
import howItWorks from './how-it-works.png';
import howItWorksBackground from './how-it-works-background.png';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import HowItWorks from '@/components/HowItWorks';
import CreateMeetup from '@/components/Button/CreateMeetup';

const contactForm = 'https://forms.gle/2sfNaPd6m1toPKZX7';

export default function Landing() {
  const [ tutorialOpen, setTutorialOpen ] = useState(false);

  const backdropStyle = {
    backgroundImage: !isMobile && `url(${ background })`,
  };

  return (
    <Div100vh>
      <style>{`
        body {
          background: #FAFAFA;
        }
      `}</style>

      <div className={ styles.landing } style={ backdropStyle }>
        <Helmet>
          <title>Calsynk</title>
        </Helmet>

        { !isMobile && <div className={ styles.logo }><Logo /></div> }
        { isMobile && <img src={ backgroundMobile } alt='' className={ styles.mobileBackground } /> }

        <main>
          <header>
            <h1>Find a time to meetup using a group calendar</h1>
            <h2>Invite your friends, build group calendars, and meet the people you care about</h2>
          </header>

          <section className={ styles.actions }>
            <CreateMeetup />
          </section>

          <section className={ styles.openTutorial }>
            <button onClick={ () => setTutorialOpen(true) } style={{ backgroundImage: `url(${ howItWorksBackground })`}}>
              <img alt='' src={ howItWorks } />

              <div>How to use Calsynk <ArrowRight /></div>
            </button>
          </section>
        </main>

        <footer>
          { isMobile && <div className={ styles.logo }><Logo /></div> }
          Made by Andrew and Nick at the University of Washington.

          <a href={ contactForm } target='_blank' rel='noopener noreferrer' className={ styles.contact }>
            Contact us
          </a>
        </footer>

        { tutorialOpen && <HowItWorks onRequestClose={ () => setTutorialOpen(false) } /> }
      </div>
    </Div100vh>
  );
};
