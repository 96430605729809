import React from 'react';
import styles from './DragActionIndicator.module.scss';
import { useDragAction, DragAction, updateDragAction } from '@/slices/calendar';
import { ReactComponent as Close } from './drag-action-indicator-close.svg';
import { useDispatch } from 'react-redux';
import Tertiary from '@/components/Button/Tertiary';

export default function DragActionIndicator() {
  const dispatch = useDispatch();
  const dragAction = useDragAction();
  if(dragAction === DragAction.NONE) {
    return null;
  }

  // Determine text & background color for different drag actions
  let text, background;
  if(dragAction === DragAction.ADD_BUSY_SPAN) {
    text = 'Drag to add a busy time'; background = '#AB7BD0';
  }

  // Perform the actual rendering
  return (
    <section className={ styles.indicator } style={{ background }}>
      <span>{ text }</span>
      <Tertiary icon={ <Close /> } onClick={ () => dispatch(updateDragAction(DragAction.NONE)) } />
    </section>
  );
}
