import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames/bind';
import styles from './Header.module.scss';
import moment from '@shared/services/moment';
import { numMobileCalendarDays, numDesktopCalendarDays } from '@/services/constants';
import { useCalendar } from '@/contexts/calendar';
import { isBrowser } from 'react-device-detect';

const c = classnames.bind(styles);

function isToday(date) {
  return moment(date).isSame(moment(), 'day');
}

// The formal definition of (n mod m). This is needed because it supports n < 0.
function formalModulo(n, m) {
  return ((n % m) + m) % m;
}

// We mount this component in a portal so we can control its position better
export default function Header({ date, label, localizer }) {
  const dayOfWeek = moment(date).format('ddd');
  const dayOfMonth = moment(date).format('D');

  const { headers } = useCalendar().portalTargets;
  const todayOffset = moment(date).diff(moment().startOf('day'), 'days');
  const headerIdx = formalModulo(todayOffset, isBrowser ? numDesktopCalendarDays : numMobileCalendarDays);
  const calendarHeader = headers[headerIdx];
  if(!calendarHeader) {
    return null;
  }

  const dateIsNextYear = moment(date).startOf('year').isAfter(moment().startOf('year'));
  const month = moment(date).format(dateIsNextYear ? 'MMM Y' : 'MMM');
  const showMonth = (headerIdx === 0);

  return ReactDOM.createPortal(
    <div className={ c('header', { today: isToday(date) }) }>
      <div className={ styles.dayOfWeekAndMonth }>
        <span className={ styles.dayOfWeek }>
          { dayOfWeek }
        </span>

        { showMonth && <span className={ styles.month }>{ month }</span> }
      </div>

      <span className={ styles.dayOfMonth }>{ dayOfMonth }</span>
    </div>,
    calendarHeader,
  );
}
