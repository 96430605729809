import React, { useState } from 'react';
import styles from './DeleteBusySpan.module.scss';
import BusySpan from '@shared/models/busy_span';
import { useMe } from '@/slices/me';
import { deleteBusySpan } from '@/slices/meetup';
import { useDispatch } from 'react-redux';
import { useSelectedBusySpan } from '@/slices/calendar';
import { popPopup } from '@/slices/view';
import Me from '@shared/models/me';
import Secondary from '@/components/Button/Secondary';
import { useAnalytics } from '@/services/analytics';
import People from './People';

export default function DeleteBusySpan() {
  const dispatch = useDispatch();
  const me = Me.fromPlainObject(useMe());
  const busySpan = BusySpan.fromPlainObject(useSelectedBusySpan());
  const [ deleting, setDeleting ] = useState(false);
  const analytics = useAnalytics();

  async function confirm() {
    setDeleting(true);
    await dispatch(deleteBusySpan(busySpan.toPlainObject()));
    analytics('delete_busy_span');
    setDeleting(false);
    dispatch(popPopup());
  }

  const calendarStyle = {};
  if(me.isBusySpanImported(busySpan)) {
    calendarStyle.color = me.getCalendarColor(me.getCalendarEventFromBusySpan(busySpan).calendarID);
  }

  return (
    <div className={ styles.container }>
      <header>
        <h1>{ busySpan.name }</h1>

        { me.isBusySpanImported(busySpan) && (
          <h2 style={ calendarStyle }>
            { me.getImportedCalendarNameFromBusySpan(busySpan) }
          </h2>
        )}
      </header>

      <div className={ styles.busyPeople }>
        <People />
      </div>

      <Secondary onClick={ confirm } loading={ deleting } className={ styles.delete }>Delete from schedule</Secondary>
      <p>Your original calendar won't be affected</p>
    </div>
  );
}
