import React, { useState } from 'react';
import styles from './Meetup.module.scss';
import { Helmet } from 'react-helmet';
import Drawer from '@/components/Drawer';
import Popup from '@/components/Popup';
import Overview from '@/components/Overview';
import GroupCalendar from '@/components/GroupCalendar';
import range from 'lodash.range';
import { numMobileCalendarDays, numDesktopCalendarDays } from '@/services/constants';
import { useMeetup } from '@/slices/meetup';
import { useMe } from '@/slices/me';
import { useView, AppScreen } from '@/slices/view';
import DragActionIndicator from '@/components/DragActionIndicator';
import CalendarContext from '@/contexts/calendar';
import Loading from '@/components/Loading';
import Splash from '@/components/Splash';
import ReactToastifyContainer from '@/components/ReactToastifyContainer';
import ViewWatcher from '@/components/ViewWatcher';
import Div100vh from 'react-div-100vh';
import { isBrowser } from 'react-device-detect';
import classnames from 'classnames/bind';

const c = classnames.bind(styles);

export default function Meetup() {
  const meetup = useMeetup();
	const { screen, drawerOpen } = useView();
	const me = useMe();
	const [toolbar, setToolbar] = useState(null);

	if(process.env.NODE_ENV === 'development') {
		console.log('meetup', meetup);
	}

	// ==============================
	// Calendar portal targets
	// ==============================
	const calendarHeaders = [];
	const setCalendarHeaders = [];
	const numCalendarDays = isBrowser ? numDesktopCalendarDays : numMobileCalendarDays;
	for(let i = 0; i < numCalendarDays; i++) {
		// Disabling this is OK because the hook order is deterministic
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[ calendarHeaders[i], setCalendarHeaders[i] ] = useState(null); // useState instead of useRef to cause re-render for children using the context
	}

  function calendarHeaderContainers() {
		return range(numCalendarDays).map((_, i) => {
			return <div key={ i } ref={ setCalendarHeaders[i] } className={ styles.calendarHeader } />
		});
	}

	const calendarContext = {
		portalTargets: {
			headers: calendarHeaders,
			toolbar,
		},
	};
	// ==============================
	// ==============================

	if(screen === AppScreen.LOADING) {
		return <Loading />;
	}

	let content = <Splash />;
	if(screen !== AppScreen.SPLASH) {
		content = (
			<div key='content' className={ styles.content }>
				<div key='overview' className={ styles.overview }>
					<Overview setToolbar={ setToolbar } />
				</div>

				<section className={ styles.inner }>
					<div key='drawer' className={ styles.drawer } style={{ zIndex: (me && drawerOpen) && 2 }}>
						<Drawer />
					</div>

					<div className={ styles.calendarContainer }>
						<div key='calendarHeaders' className={ styles.calendarHeaders }>
							{ calendarHeaderContainers() }
						</div>

						<main key='calendar' className={ c('calendar', { empty: meetup.busySpans.length === 0 }) }>
							<CalendarContext.Provider value={ calendarContext }>
								<GroupCalendar />
							</CalendarContext.Provider>
						</main>

						<DragActionIndicator />
					</div>
				</section>

				<div key='popup' className={ styles.popup }>
					<Popup />
				</div>
			</div>
		);
	}

  return (
    <>
			<Helmet key='helmet'>
				{ typeof meetup.name !== 'undefined' && <title>{ `${ meetup.name } - Calsynk` }</title> }
      </Helmet>

			<ViewWatcher />
			<ReactToastifyContainer />

			<Div100vh>
				{ content }
			</Div100vh>
		</>
  );
}
