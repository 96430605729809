import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { pushPopup, useCurrentPopup, Popup } from '@/slices/view';
import moment from '@shared/services/moment';
import { hourSpan } from '@/services/constants';

export const schedulingSlice = createSlice({
  name: 'scheduling',

  initialState: {
    tentativeDate: 0, // A UNIX-second timestamp that only represents the date. The time is ignored.
    tentativeStartTime: 0, // A UNIX-second timestamp that only represents the start hour. The date is ignored.
    tentativeEndTime: 0, // A UNIX-second timestamp that only represents the end hour. The date is ignored.
  },

  reducers: {
    updateTentativeDate: (state, { payload }) => {
      state.tentativeDate = payload;
    },

    updateTentativeStartTime: (state, { payload }) => {
      // Ensure that the end time is after the start time
      if(payload > state.tentativeEndTime) {
        state.tentativeEndTime = moment.unix(payload).add(1, 'hour').unix();
      }

      state.tentativeStartTime = payload;
    },

    updateTentativeEndTime: (state, { payload }) => {
      // Ensure that the end time is after the start time
      if(payload < state.tentativeStartTime) {
        state.tentativeStartTime = moment.unix(payload).subtract(1, 'hour').unix();
      }

      state.tentativeEndTime = payload;
    },

    // payload is a UNIX-second timestamp
    resetScheduling: (state, { payload }) => {
      const startMoment = moment.unix(payload);
      state.tentativeDate = startMoment.unix();

      const currentHour = Math.max(hourSpan.minHour, Math.min(hourSpan.maxHour, startMoment.hour())); // Respect the valid time range
      state.tentativeStartTime = moment({ hour: currentHour }).unix();
      state.tentativeEndTime = moment.unix(state.tentativeStartTime).add(1, 'hour').unix();
    },
  },
});

export const {
  updateTentativeDate,
  updateTentativeStartTime,
  updateTentativeEndTime,
  resetScheduling,
} = schedulingSlice.actions;

export const beginScheduling = startMoment => dispatch => {
  dispatch(resetScheduling(startMoment));
  dispatch(pushPopup(Popup.SCHEDULE));
}

// =================================
// Selectors
// =================================
export function useScheduling() {
  return useSelector(state => state.scheduling);
}

export function useTentativeTimeSpan() {
  const { tentativeDate, tentativeStartTime, tentativeEndTime } = useScheduling();
  const popup = useCurrentPopup();
  if(popup !== Popup.SCHEDULE) {
    return null;
  }

  // TODO: using hour: and minute: like this might cause timezone problems
  const meetupStart = moment.unix(tentativeDate);
  meetupStart.set({
    hour: moment.unix(tentativeStartTime).hour(),
    minute: moment.unix(tentativeStartTime).minute()
  });

  const meetupEnd = moment.unix(tentativeDate);
  meetupEnd.set({
    hour: moment.unix(tentativeEndTime).hour(),
    minute: moment.unix(tentativeEndTime).minute()
  });

  return {
    start: meetupStart.unix(),
    end: meetupEnd.unix(),
  };
}

export default schedulingSlice.reducer;
