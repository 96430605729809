import React from 'react';
import PropTypes from 'prop-types';
import styles from './SwipeableDrawer.module.scss';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import './SwipeableDrawer.scss';

function SwipeableDrawer({ content: Content, header: Header, open, setOpen }) {
  const overlayStyle = {
    background: 'transparent',
  };

  return (
    <SwipeableBottomSheet
      overflowHeight={ SwipeableDrawer.closedHeight }
      marginTop={ 0 }
      open={ open }
      onChange={ setOpen }
      overlayStyle={ overlayStyle }
      topShadow={ false }
      shadowTip={ false }
    >
      <div className={ styles.inner }>
        <div className={ styles.header }>
          <Header />
        </div>

        <div className={ styles.content }>
          <Content />
        </div>
      </div>
    </SwipeableBottomSheet>
  );
}

SwipeableDrawer.propTypes = {
  header: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

SwipeableDrawer.closedHeight = 186; // px

export default SwipeableDrawer;
