import React from 'react';
import * as dates from 'date-arithmetic';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import Week from 'react-big-calendar/lib/Week';
import { numMobileCalendarDays, numDesktopCalendarDays } from '@/services/constants';
import { isBrowser } from 'react-device-detect';

class MobileView extends React.Component {
  static propTypes = Week.propTypes;
  static defaultProps = Week.defaultProps;

  static navigate = Week.navigate;
  static title = Week.title;

  static range(date, { localizer }) {
    const numCalendarDays = isBrowser ? numDesktopCalendarDays : numMobileCalendarDays;

    let start = new Date(date);
    start.setHours(0,0,0,0);
    let end = dates.add(start, numCalendarDays - 1, 'day');

    let current = start;
    let range = [];

    while(dates.lte(current, end, 'day')) {
      range.push(current);
      current = dates.add(current, 1, 'day');
    }

    return range;
  }

  render() {
    let { date, ...props } = this.props;
    let range = MobileView.range(date, this.props);

    return <TimeGrid {...props} range={range} eventOffset={15} />
  }
}

export default MobileView;
