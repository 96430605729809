import { configureStore } from '@reduxjs/toolkit';
import meetup from '@/slices/meetup';
import view from '@/slices/view';
import me from '@/slices/me';
import scheduling from '@/slices/scheduling';
import calendar from '@/slices/calendar';

export default configureStore({
  reducer: {
    meetup,
    view,
    me,
    scheduling,
    calendar,
  },
});
