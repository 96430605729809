import React from 'react';
import PropTypes from 'prop-types';
import styles from './NewPeople.module.scss';
import { useMeetup } from '@/slices/meetup';
import store from 'store';
import PortraitSet from '@/components/PortraitSet';
import { parseName } from 'humanparser';

function NewPeople({ closeToast }) {
  const meetup = useMeetup();
  const peopleSeen = (store.get('v1_people_seen') || {})[meetup.id] || [];
  const peopleSeenIDs = peopleSeen.map(person => person.id);
  const newPeople = meetup.people.filter(person => !peopleSeenIDs.includes(person.id));

  if(newPeople.length === 0) {
    return <section />;
  }

  return (
    <section className={ styles.toast }>
      <PortraitSet people={ newPeople } max={ 2 } />

      { newPeople.length === 1 && (
        <p>
          <strong>{ parseName(newPeople[0].name).firstName }</strong> recently joined your meetup! Their calendars appear grey on the group schedule.
        </p>
      ) }

      { newPeople.length > 1 && (
        <p>
          { newPeople.length } people recently joined your meetup! Their calendars appear grey on the group schedule.
        </p>
      ) }
    </section>
  );
}

NewPeople.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default NewPeople;
