import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames/bind';
import styles from './Controls.module.scss';
import Icon from '@/components/Button/Icon';
import Secondary from '@/components/Button/Secondary';
import Tertiary from '@/components/Button/Tertiary';
import leftArrow from './left-arrow.png';
import rightArrow from './right-arrow.png';
import { useDate, updateDate } from '@/slices/calendar';
import { useAnalytics } from '@/services/analytics';
import moment from '@shared/services/moment';
import { useCalendar } from '@/contexts/calendar';
import { useDispatch } from 'react-redux';
import { numDesktopCalendarDays } from '@/services/constants';

const c = classnames.bind(styles);
const feedbackForm = 'https://forms.gle/1aGn4cvSp3iYJfSh6';

// We mount this component in a portal so we can control its position better
export default function Toolbar() {
  const dispatch = useDispatch();
  const date = useDate();
  const analytics = useAnalytics();
  const diff = moment.duration(numDesktopCalendarDays, 'days').asSeconds();

  const { toolbar } = useCalendar().portalTargets;
  if(!toolbar) {
    return null;
  }

  function prev() {
    dispatch(updateDate(date - diff));
    analytics('calendar_prev');
  }

  function today() {
    dispatch(updateDate(moment().unix()));
    analytics('calendar_today');
  }

  function next() {
    dispatch(updateDate(date + diff));
    analytics('calendar_next');
  }

  return ReactDOM.createPortal(
    <div className={ styles.container }>
      <div className={ styles.controls }>
        <section className={ styles.navigation }>
          <Icon onClick={ prev } className={ styles.button } icon={ leftArrow } />
          <Secondary onClick={ today } className={ c('button', 'today') }>Today</Secondary>
          <Icon onClick={ next } className={ styles.button } icon={ rightArrow } />
        </section>
      </div>

      <a href={ feedbackForm } target='_blank' rel='noopener noreferrer' className={ styles.feedback }>
        <Tertiary onClick={ () => analytics('give_feedback') } className={ styles.feedback }>
          Give feedback
        </Tertiary>
      </a>
    </div>,
    toolbar,
	);
}
