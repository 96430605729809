import React, { useState, useEffect } from 'react';
import styles from './SignIn.module.scss';
import GoogleSignIn from '@/components/Button/GoogleSignIn';
import OutlookSignIn from '@/components/Button/OutlookSignIn';
import { useMe, googleSignIn, outlookSignIn, useForceImport, updateForceImport } from '@/slices/me';
import { useDispatch } from 'react-redux';
import { useMeetup } from '@/slices/meetup';
import { pushPopup, clearPopups, Popup } from '@/slices/view';
import { useHistory } from 'react-router-dom';
import store from 'store';
import MobileDiv100vh from '@/components/MobileDiv100vh';
import { useAnalytics } from '@/services/analytics';
import { isMobile } from 'react-device-detect';
import Logo from '@/components/Logo';

export default function SignIn() {
  const me = useMe();
  const forceImport = useForceImport();
  const meetup = useMeetup();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const history = useHistory();
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if(!me || forceImport) {
      return;
    }

    setLoading(false);
    store.set('splash_v1_seen', true);
    dispatch(clearPopups());

    const justCreated = history.location.state && history.location.state.justCreated;
    if(justCreated) {
      if(isMobile) {
        dispatch(pushPopup(Popup.INVITE));
      }

      dispatch(pushPopup(Popup.CREATING));
    }
  }, [ me, meetup, dispatch, history, forceImport ]);

  async function startGoogleSignIn() {
    setLoading(true);
    await dispatch(googleSignIn({ importCalendar: true, forceImport }));
    dispatch(updateForceImport(false));
    analytics('google_sign_in');
  }

  async function startOutlookSignIn() {
    setLoading(true);
    await dispatch(outlookSignIn({ importCalendar: true, forceImport }));
    dispatch(updateForceImport(false));
    analytics('outlook_sign_in');
  }

  if(loading) {
    return (
      <div className={ styles.loading }>
        <div className={ styles.loader }>
          <div></div><div></div><div></div><div></div>
        </div>

        <h1>Signing in</h1>
      </div>
    );
  }

  return (
    <MobileDiv100vh>
      <div className={ styles.container }>
        <div className={ styles.finish }>
          <header>
            { me && <h1>Import your calendar</h1> }
            { !me && <h1>Log in/sign up with your calendar</h1> }
            <h2>Add your calendar and invite your friends to find a time to meetup</h2>
          </header>

          <section>
            <ul>
              <li><GoogleSignIn onClick={ startGoogleSignIn } /></li>
              <li><OutlookSignIn onClick={ startOutlookSignIn } /></li>
            </ul>
          </section>
        </div>

        { isMobile && (
          <footer>
            <Logo />
          </footer>
        ) }
      </div>
    </MobileDiv100vh>
  );
}
