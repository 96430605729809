import React from 'react';
import PropTypes from 'prop-types';
import styles from './LeftDrawer.module.scss';

function LeftDrawer({ header: Header, content: Content }) {
  return (
    <div className={ styles.inner }>
      <div className={ styles.header }>
        <Header />
      </div>

      <div className={ styles.content }>
        <Content />
      </div>
    </div>
  );
}

LeftDrawer.propTypes = {
  header: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
};

export default LeftDrawer;
