import React from 'react';
import PropTypes from 'prop-types';
import styles from './Overview.module.scss';
import { useMeetup } from '@/slices/meetup';
import Me from '@/components/Me';
import Logo from '@/components/Logo';
import { isBrowser } from 'react-device-detect';

export default function Overview({ setToolbar }) {
  const meetup = useMeetup();

  return (
    <section className={ styles.container }>
      { isBrowser && <Logo /> }

      <section className={ styles.nameAndToolbar }>
        <h1 className={ styles.name }>
          { meetup.name }
        </h1>

        { isBrowser && <div ref={ setToolbar } /> }
      </section>

      <section className={ styles.me }>
        <Me />
      </section>
    </section>
  );
}

Overview.propTypes = {
  setToolbar: PropTypes.func.isRequired,
};
