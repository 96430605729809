import React, { useState } from 'react';
import styles from './Me.module.scss';
import Portrait from '@/components/Portrait';
import Tertiary from '@/components/Button/Tertiary';
import { pushPopup, Popup } from '@/slices/view';
import { useMe, signOut } from '@/slices/me';
import { useDispatch } from 'react-redux';
import Logo from '@/components/Logo';
import { landing } from '@shared/services/url';
import Div100vh from 'react-div-100vh';
import { useAnalytics } from '@/services/analytics';
import { isBrowser } from 'react-device-detect';
import OutsideClickHandler from 'react-outside-click-handler';

const feedbackForm = 'https://forms.gle/1aGn4cvSp3iYJfSh6';

export default function Me() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const me = useMe();
  const [ open, setOpen ] = useState(false);
  const [ signingOut, setSigningOut ] = useState(false);

  if(!me) {
    return (
      <Tertiary onClick={ () => dispatch(pushPopup(Popup.SIGN_IN)) } className={ styles.signIn }>
        Sign in
      </Tertiary>
    );
  }

  function toggleDrawer() {
    if(open) {
      closeDrawer();
    } else {
      openDrawer();
    }
  }

  function openDrawer() {
    setOpen(true);
    analytics('open_me_drawer');
  }

  function closeDrawer() {
    setOpen(false);
    analytics('close_me_drawer');
  }

  async function doSignOut() {
    setSigningOut(true);
    await dispatch(signOut());
    analytics('sign_out');
    setSigningOut(false);
    closeDrawer();
  }

  const mini = <Portrait person={ me.person } onClick={ toggleDrawer } />;

  if(open) {
    const inner = (
      <div className={ styles.container }>
        { !isBrowser && (
          <header>
            <Logo />
            <button onClick={ closeDrawer }>X</button>
          </header>
        ) }

        <section className={ styles.person }>
          <div><Portrait person={ me.person } /></div>
          <div>
            <h1>{ me.person.name }</h1>
            { isBrowser && <h2>{ me.email }</h2> }
          </div>
        </section>

        <ul>
          <li>
            <a href={ landing() }>
              <Tertiary onClick={ () => analytics('me_create_meetup') } className={ styles.darkText }>
                Create a new meetup
                <span className={ styles.description }>You can come back to this meetup via its URL</span>
              </Tertiary>
            </a>
          </li>

          <li>
            <Tertiary onClick={ doSignOut } className={ styles.darkText } loading={ signingOut }>Sign out</Tertiary>
          </li>

          <li>
            <a href={ feedbackForm } target='_blank' rel='noopener noreferrer'>
              <Tertiary onClick={ () => analytics('give_feedback') } className={ styles.darkText }>
                Give feedback
              </Tertiary>
            </a>
          </li>
        </ul>
      </div>
    );
    if(isBrowser) {
      return (
        <OutsideClickHandler onOutsideClick={ closeDrawer }>
          { inner }
          { mini }
        </OutsideClickHandler>
      );
    }

    return (
      <Div100vh>
        <section className={ styles.details }>
          { inner }
        </section>
      </Div100vh>
    );
  }

  return mini;
}
