import axios from 'axios';
import Meetup from '@shared/models/meetup';
import Me, { NullMe } from '@shared/models/me';
import { absoluteAPI } from '@shared/services/url';
import temporaryWindow from '@/services/temporary_window';
import EmailSignInResponse from '@/models/email_sign_in_response';
import AllowDeviceResponse from '@/models/allow_device_response';
import moment from '@shared/services/moment';
import { exception } from '@/services/analytics';

const http = axios.create({
	baseURL: `/v1`,
	timeout: moment.duration(15, 'seconds').asMilliseconds(),
	withCredentials: true,
});

function interceptSuccess(response) {
	return response;
}

function interceptError(error) {
	exception(error.message, false);
	return Promise.reject(error);
}

http.interceptors.request.use(interceptSuccess, interceptError);
http.interceptors.response.use(interceptSuccess, interceptError);

export default class MeetupAPI {
	constructor(meetupID) {
		this.meetupID = meetupID;
	}

	googleSignIn = async ({ deviceKey, importCalendar }) => {
		const url = new URL(absoluteAPI() + `/v1/meetup/${ this.meetupID }/auth/google`);
		url.searchParams.append('deviceKey', deviceKey);
		url.searchParams.append('importCalendar', importCalendar || false);
		return temporaryWindow(url.toString());
	}

	facebookSignIn = async ({ deviceKey }) => {
		const url = new URL(absoluteAPI() + `/v1/meetup/${ this.meetupID }/auth/facebook`);
		url.searchParams.append('deviceKey', deviceKey);
		return temporaryWindow(url.toString());
	}

	outlookSignIn = async ({ deviceKey }) => {
		const url = new URL(absoluteAPI() + `/v1/meetup/${ this.meetupID }/auth/outlook`);
		url.searchParams.append('deviceKey', deviceKey);
		return temporaryWindow(url.toString());
	}

	emailSignIn = async ({ email, deviceKey, name }) => {
		const params = { email, deviceKey, name };

		try {
			const response = await http.post(`/meetup/${ this.meetupID }/auth/device`, params);
			return new EmailSignInResponse(response);
		} catch({ response }) {
			return new EmailSignInResponse(response);
		}
	}

	allowDevice = async secret => {
		try {
			const response = await http.get(`/meetup/${ this.meetupID }/auth/device/allow/${ secret }`);
			return new AllowDeviceResponse(response);
		} catch({ response }) {
			return new AllowDeviceResponse(response);
		}
	}

	setVisibleImportedCalendars = async importedCalendars => {
		return http.put(`/meetup/${ this.meetupID }/imports/visible`, {
			importedCalendars: importedCalendars.map(calendar => calendar.toPlainObject()),
		});
	}

	signOut = async () => {
		return http.get(`/meetup/${ this.meetupID }/auth/sign_out`);
	}

	me = async () => {
		try {
			const response = await http.get(`/meetup/${ this.meetupID }/auth/me`);
			return Me.fromPlainObject(response.data);
		} catch {
			return new NullMe();
		}
	}

	fetch = async () => {
		const response = await http.get(`/meetup/${ this.meetupID }`);
		return Meetup.fromPlainObject(response.data);
	}

	setName = async name => {
		return http.put(`/meetup/${ this.meetupID }/name`, { name });
  }

	addBusySpans = async busySpans => {
		return http.put(`/meetup/${ this.meetupID }/busy_spans/add`, { busySpans: busySpans.map(busySpan => busySpan.toPlainObject()) });
	}

	deleteBusySpan = async busySpan => {
		return http.put(`/meetup/${ this.meetupID }/busy_spans/delete`, { busySpan: busySpan.toPlainObject() });
	}

	setYield = async timeSpan => {
		return http.put(`/meetup/${ this.meetupID }/yield`, { timeSpan: timeSpan.toPlainObject() });
	}

	static async createMeetup(name) {
		const response = await http.post(`/meetup`, { name });
		return response.data;
	}
}
