import React from 'react';
import styles from './Closed.module.scss';
import Participants from '@/components/Participants';
import Primary from '@/components/Button/Primary';
import { useMe, updateForceImport } from '@/slices/me';
import { useCanSchedule, useMeetup } from '@/slices/meetup';
import { pushPopup, Popup } from '@/slices/view';
import { useDispatch } from 'react-redux';
import { isBrowser } from 'react-device-detect';

export default function Closed() {
  const me = useMe();
  const { people } = useMeetup();
  const dispatch = useDispatch();
  const canSchedule = useCanSchedule();

  function join() {
    dispatch(updateForceImport(false));
    dispatch(pushPopup(Popup.SIGN_IN));
  }

  let title;
  if(me && people.length === 1) {
    title = 'Invite your friends to schedule a meetup';
  } else {
    const peoplePlural = (people.length === 1) ? 'person is' : 'people are';
    title = `${ people.length } ${ peoplePlural } in this meetup`;
  }

  return (
    <div className={ styles.drawer }>
      <h1>{ title }</h1>
      { me && <Participants /> }
      { (isBrowser && canSchedule) && <p className={ styles.dragToSchedule }>Drag an empty area to schedule</p> }
      { !me && <Primary onClick={ join }>Join this meetup</Primary> }
    </div>
  );
}
