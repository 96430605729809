import React from 'react';
import styles from './NoEventsOverlay.module.scss';

export default function NoEventsOverlay() {
  return (
    <div className={ styles.overlay }>
      <div className={ styles.inner }>
        <h1>No one has imported their calendars yet</h1>
        <h2>Import your calendars to see available times</h2>
      </div>
    </div>
  );
}
