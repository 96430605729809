import httpStatus from 'http-status-codes';

export default class APIResponse {
  constructor(response) {
    this.response = response;

    if(!this.isValid()) {
      console.log('response', response);
      throw new Error('Invalid response ' + response.status);
    }
  }

  // Returns whether or not this API response is valid (i.e. has some expected status/data)
  // Subclasses should add additional conditions to this
  isValid() {
    return this.success();
  }

  getStatus() {
    return this.response.status;
  }

  success() {
    return this.getStatus() === httpStatus.OK;
  }
}
