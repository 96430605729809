import React from 'react';
import PropTypes from 'prop-types';
import styles from './Textbox.module.scss';

function Textbox({ placeholder, value, onChange }) {
  return (
    <input
      type='text'
      placeholder={ placeholder }
      value={ value }
      onChange={ event => onChange(event.target.value) }
      className={ styles.textbox }
    />
  );
}

Textbox.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Textbox;
