import { useMeetup } from '@/slices/meetup';
import { useMe } from '@/slices/me';
import { useView } from '@/slices/view';
import { CalendarType } from '@shared/models/calendar_event';

const measurementID = 'G-0Z5V9QZGZ5';
const options = {
  app_name: 'Calsynk',
  app_version: 'ALPHA',
  page_title: '',
  custom_map: {
    dimension1: 'meetup_id',
    dimension2: 'meetup_yielded',
    dimension3: 'meetup_num_people',
    //dimension4 was formerly time_on_page_ms
    // dimension5: 'tutorial_visible',
    // dimension6: 'modal_visible',
    dimension7: 'is_signed_in',
    // dimension8: 'num_unsaved_busy_spans',
    // dimension9: 'is_confirming_yield',
    dimension10: 'num_visible_google_calendars',
    dimension11: 'num_total_google_calendars',
    dimension12: 'num_visible_outlook_calendars',
    dimension13: 'num_total_outlook_calendars',
    dimension14: 'screen',
    dimension15: 'popups',
    dimension16: 'drawer_open',
    metric1: 'time_on_page_ms',
  },
};

function gtag() {
  window.dataLayer.push(arguments);
}

// React hook that returns a method to record an event
export function useAnalytics() {
  const meetup = useMeetup();
  const view = useView();
  const me = useMe();

  const googleCalendars = me ? me.importedCalendars.filter(({ calendarType }) => calendarType === CalendarType.GOOGLE) : [];
  const outlookCalendars = me ? me.importedCalendars.filter(({ calendarType }) => calendarType === CalendarType.OUTLOOK) : [];

  function logEvent(event, extraContext) {
    const context = {
      time_on_page_ms: window.performance ? Math.round(performance.now()) : -1,
      meetup_id: meetup.id,
      meetup_yielded: meetup.selectedTime !== null,
      meetup_num_people: meetup.people.length,
      is_signed_in: me !== null,
      num_visible_google_calendars: googleCalendars.filter(({ visible }) => visible).length,
      num_total_google_calendars: googleCalendars.length,
      num_visible_outlook_calendars: outlookCalendars.filter(({ visible }) => visible).length,
      num_total_outlook_calendars: outlookCalendars.length,
      screen: view.screen,
      popups: view.popups.join(' '),
      drawer_open: view.drawerOpen,
      ...(extraContext ? extraContext : {}),
    };
    gtag('event', event, context);

    // Log events to console in dev environment
    if(process.env.NODE_ENV === 'development') {
      console.log(event, context);
    }
  }

  return logEvent;
}

export class AnalyticsContext {
  constructor() {
    this.time_on_page_ms = window.performance ? Math.round(performance.now()) : -1;
  }

  getDimensions() {
    return {};
  }

  getMetrics() {
    return {
      time_on_page_ms: this.time_on_page_ms,
    };
  }

  get() {
    return {
      ...this.getDimensions(),
      ...this.getMetrics(),
    };
  }
}

// page_title: String
export function init(page_title) {
  gtag('js', new Date());
  gtag('config', measurementID, {
    ...options,
    page_title,
  });
}


// screen_name: String
// context: instanceof AnalyticsContext
export function screen(screen_name, context) {
  gtag('event', 'screen_view', {
    screen_name,
    ...context.get(),
  });
}

// event: String
// context: instanceof AnalyticsContext
export function simpleEvent(event, context) {
  gtag('event', event, context.get());
}

// description: String
// fatal: Boolean
export function exception(description, fatal) {
  gtag('event', 'exception', { description, fatal });
}
