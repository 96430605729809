import axios from 'axios';
import moment from '@shared/services/moment';
import { exception } from '@/services/analytics';
import { absoluteAPI } from '@shared/services/url';
import temporaryWindow from '@/services/temporary_window';

const http = axios.create({
	baseURL: `/v1`,
	timeout: moment.duration(15, 'seconds').asMilliseconds(),
	withCredentials: true,
});

function interceptSuccess(response) {
	return response;
}

function interceptError(error) {
	exception(error.message, false);
	return Promise.reject(error);
}

http.interceptors.request.use(interceptSuccess, interceptError);
http.interceptors.response.use(interceptSuccess, interceptError);

export async function fetchMeetup(meetupID) {
  const response = await http.get(`/meetup/${ meetupID }`);
  return response.data;
}

export async function googleSignIn({ meetupID, importCalendar, forceImport }) {
  const url = new URL(absoluteAPI() + `/v1/meetup/${ meetupID }/auth/google`);
  url.searchParams.append('importCalendar', importCalendar || false);
  url.searchParams.append('forceImport', forceImport || false);
  return temporaryWindow(url.toString());
}

export async function outlookSignIn({ meetupID, importCalendar, forceImport }) {
  const url = new URL(absoluteAPI() + `/v1/meetup/${ meetupID }/auth/outlook`);
  url.searchParams.append('importCalendar', importCalendar || false);
  url.searchParams.append('forceImport', forceImport || false);
  return temporaryWindow(url.toString());
}

export async function signOut(meetupID) {
  return http.get(`/meetup/${ meetupID }/auth/sign_out`);
}

export async function updateVisibleImportedCalendars(meetupID, calendars) {
  return http.put(`/meetup/${ meetupID }/imports/visible`, {
    importedCalendars: calendars,
  });
}

export async function deleteImportedCalendars(meetupID) {
  return http.delete(`/meetup/${ meetupID }/imports`);
}

export async function fetchMe(meetupID) {
  try {
    const response = await http.get(`/meetup/${ meetupID }/auth/me`);
    return response.data;
  } catch {
    return null;
  }
}

export async function updateName(meetupID, name) {
  return http.put(`/meetup/${ meetupID }/name`, { name });
}

export async function createBusySpans(meetupID, busySpans) {
  const response = await http.put(`/meetup/${ meetupID }/busy_spans/add`, { busySpans });
  return response.data;
}

export async function deleteBusySpan(meetupID, busySpan) {
  return http.put(`/meetup/${ meetupID }/busy_spans/delete`, { busySpan });
}

export async function updateSelectedTime(meetupID, timeSpan) {
  return http.put(`/meetup/${ meetupID }/yield`, { timeSpan });
}

export async function createMeetup(name) {
  const response = await http.post(`/meetup`, { name });
  return response.data;
}
