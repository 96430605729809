import React, { useEffect } from 'react';
import NotFound from '@/components/NotFound';
import { init } from '@/services/analytics';

export default function NotFoundContainer() {
  useEffect(() => {
    init('Page not found');
  }, []);

  return <NotFound />;
}
