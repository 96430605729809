import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'react-user-avatar';
import './Portrait.scss';

function Portrait({ size, person, onClick }) {
	return (
		<span title={ person.name } onClick={ onClick }>
			<UserAvatar size={ size } name={ person.name } src={ person.photo } />
		</span>
	);
}

Portrait.propTypes = {
	person: PropTypes.shape({
		name: PropTypes.string.isRequired,
		photo: PropTypes.string,
	}).isRequired,
	size: PropTypes.number,
	onClick: PropTypes.func,
};

Portrait.defaultProps = {
	size: 28,
	onClick: () => {},
};

export default Portrait;
