import React from 'react';
import PropTypes from 'prop-types';
import styles from './TentativeConflicts.module.scss';
import uniqBy from 'lodash.uniqby';
import { useMeetup } from '@/slices/meetup';
import TimeSpan from '@shared/models/time_span';
import BusySpan from '@shared/models/busy_span';
import Portrait from '@/components/Portrait';
import TimeSpanContainer from '@/models/time_span_container';
import Meetup from '@shared/models/meetup';

function timeSpanToListItem(timeSpan, idx) {
  const { start, end } = timeSpan;

  return (
    <li key={ idx }>
      { start.format('hh:mm A') } - { end.format('hh:mm A') }
    </li>
  );
}

export default function TentativeConflicts({ timeSpan, textFactory }) {
  const meetup = Meetup.fromPlainObject(useMeetup());
  const tentativeTimeSpan = TimeSpan.fromPlainObject(timeSpan);
  const conflicts = meetup.busySpans.filter(busySpan => busySpan.time.intersects(tentativeTimeSpan));
  const conflictPeople = uniqBy(
    conflicts.map(busySpan => busySpan.person),
    person => person.hashKey(),
  );

  if(conflictPeople.length === 0) {
    return null;
  }

  function personToListItem(person) {
    function personIsBusyAtMoment(moment) {
      return meetup.getBusyPeopleAtMoment(moment).has(person);
    }

    const personConflicts = TimeSpanContainer
      .fromBusySpans(meetup.busySpans.concat([ BusySpan.fromTimeSpan(tentativeTimeSpan) ]))
      .withStart(tentativeTimeSpan.start)
      .withEnd(tentativeTimeSpan.end)
      .disjointByBoundaryDifference((a, b) =>
        personIsBusyAtMoment(a) !== personIsBusyAtMoment(b) ||
        a.isSame(tentativeTimeSpan.end) || b.isSame(tentativeTimeSpan.end)
      )
      .timeSpans
      .filter(timeSpan => personIsBusyAtMoment(timeSpan.start));

    return (
      <li key={ person.id }>
        <section>
          <Portrait size={ 24 } person={ person } />
          <label>{ person.name }</label>
        </section>

        <ul>
          { personConflicts.map(timeSpanToListItem) }
        </ul>
      </li>
    );
  }

  return (
    <section className={ styles.conflicts }>
      <h1>
        { textFactory(conflictPeople) }
      </h1>

      <ul>
        { conflictPeople.map(personToListItem) }
      </ul>
    </section>
  );
}

TentativeConflicts.propTypes = {
  timeSpan: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  textFactory: PropTypes.func.isRequired, // Takes the list of conflicting people and returns header text
};
