import React, { useState } from 'react';
import styles from './Cancel.module.scss';
import Primary from '@/components/Button/Primary';
import { useMeetup, updateSelectedTime } from '@/slices/meetup';
import { clearPopups } from '@/slices/view';
import { useDispatch } from 'react-redux';
import TimeSpan from '@shared/models/time_span';

export default function Cancel() {
  const dispatch = useDispatch();
  const meetup = useMeetup();
  const [ canceling, setCanceling ] = useState(false);
  const { start, end } = TimeSpan.fromPlainObject(meetup.selectedTime);
  if(!start && !end) {
    return null;
  }

  async function confirm() {
    setCanceling(true);
    await dispatch(updateSelectedTime(null));
    setCanceling(false);
    dispatch(clearPopups());
  }

  return (
    <div className={ styles.cancel }>
      <header>
        <h1>Are you sure?</h1>
        <h2>Your friends <strong>won't</strong> be notified about cancelation</h2>
      </header>

      <section className={ styles.details }>
        <section>
          <span className={ styles.time }>{ start.format('hh:mm') }</span>
          <span className={ styles.amPM }>{ start.format('A') }</span>
          <hr />
          <span className={ styles.time }>{ end.format('hh:mm') }</span>
          <span className={ styles.amPM }>{ end.format('A') }</span>
        </section>

        <div className={ styles.name }>{ meetup.name }</div>
        <div className={ styles.date }>{ start.format('dddd MMMM DD') } - { start.fromNow() }</div>
      </section>

      <section>
        <Primary onClick={ confirm } loading={ canceling }>Cancel meetup time</Primary>
      </section>
    </div>
  );
}
