import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvitationComplete.module.scss';

function InvitationComplete({ closeToast }) {
  return (
    <section className={ styles.toast }>
      <h1>Invitation complete</h1>
      <p>Once your friends add their calendars, you can schedule a time to meetup</p>
    </section>
  );
}

InvitationComplete.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default InvitationComplete;
