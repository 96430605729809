import React, { useMemo } from 'react';
import styles from './GroupCalendar.module.scss';
import Calendar from '@/components/Calendar';
import Event from './Event';
import { useMeetup, useCanSchedule } from '@/slices/meetup';
import { useDragAction, DragAction } from '@/slices/calendar';
import { useMe, usePublicBusySpans, createBusySpans } from '@/slices/me';
import { useTentativeTimeSpan } from '@/slices/scheduling';
import { hourSpan } from '@/services/constants';
import NoEventsOverlay from '@/components/Calendar/NoEventsOverlay';
import { longUniqueID } from '@shared/services/unique_id';
import { isBrowser } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import moment from '@shared/services/moment';
import { cantSchedule } from '@/services/toasts';
import throttle from 'lodash.throttle';
import { updateTentativeDate, updateTentativeStartTime, updateTentativeEndTime } from '@/slices/scheduling';
import { pushPopup, Popup } from '@/slices/view';
import TimeSpanContainer from '@/models/time_span_container';
import Meetup from '@shared/models/meetup';
import TimeSpan from '@shared/models/time_span';
import { pushAll } from '@/services/arrays';

export default function GroupCalendar() {
  const dispatch = useDispatch();
  const me = useMe();
  const myPublicBusySpans = usePublicBusySpans();
  const meetup = Meetup.fromPlainObject(useMeetup());
  const tentativeTimeSpan = useTentativeTimeSpan();
  const dragAction = useDragAction();
  const canSchedule = useCanSchedule();

  // Compute the set of busy spans
  // TODO: wrap it in useMemo
  // - The user's own busy spans will show first, unmodified
  // - Others' busy spans will be merged into "one person" and "multiple people" busy spans
  // - If there's a tentative time, it will cut out others' busy spans at the tentative time and insert the tentative time at that spot
  //      - The user's own busy span's won't be affected
  const busySpans = useMemo(() => {
    // Classifies a and b into 3 categories:
    //  - "0 people are busy at that time"
    //  - "1 person is busy at that time"
    //  - "> 1 person is busy at that time"
    //
    // Returns whether or not a and b fall in different categories or are on different days
    function singleOrMultiBusyBoundaryDifference(a, b) {
      if(!a.isSame(b, 'day')) {
        return true;
      }

      const numBusyA = meetup.getBusyPeopleAtMoment(a).size;
      const numBusyB = meetup.getBusyPeopleAtMoment(b).size;
      return (numBusyA === 0 && numBusyB !== 0) ||
        (numBusyA === 1 && numBusyB !== 1) ||
        (numBusyA > 1 && numBusyB <= 1);
    }

    const busySpans = myPublicBusySpans.slice(); // Start with the user's own busy spans

    // Computed the merge of others' busy times
    const otherBusySpans = meetup.busySpans.filter(busySpan => !me || busySpan.person.id !== me.person.id);
    let mergedOthersTimes = TimeSpanContainer.fromBusySpans(otherBusySpans)
      .disjointByBoundaryDifference(singleOrMultiBusyBoundaryDifference) // Split them based on 0, 1, or > 1 people busy
      .filter(timeSpan => meetup.getBusyPeopleAtMoment(timeSpan.start).size > 0); // Remove times with 0 people busy

    // Override others' busy spans with the tentative time
    if(tentativeTimeSpan) {
      const tentative = TimeSpan.fromPlainObject(tentativeTimeSpan);
      mergedOthersTimes = mergedOthersTimes
        .withoutTimeRange(tentative)
        .concat(new TimeSpanContainer([ tentative ]));
    }

    // Convert others' time spans + the tentative time into busy spans
    pushAll(
      busySpans,
      mergedOthersTimes.toBusySpans().map(busySpan => busySpan.toPlainObject())
    );
    return busySpans;
  }, [ me, meetup, myPublicBusySpans, tentativeTimeSpan ]);
  // =======================

  // Handle dragging
  const throttledCantSchedule = throttle(cantSchedule, moment.duration(5, 'seconds').asMilliseconds(), { trailing: false });
  function onSelecting() {
    if(dragAction === DragAction.ADD_BUSY_SPAN) {
      return true;
    }

    if(!canSchedule) {
      throttledCantSchedule();
    }

    return canSchedule;
  }

  function onTryCreate({ start, end }) {
    const time = {
      start: moment(start).unix(),
      end: moment(end).unix(),
    };

    // Add a busy time manually
    if(dragAction === DragAction.ADD_BUSY_SPAN) {
      const busySpan = { id: longUniqueID(), name: 'You are busy', time };
      dispatch(createBusySpans([ busySpan ]));
      return;
    }

    // Schedule the meeting
    if(canSchedule) {
      dispatch(updateTentativeDate(time.start));
      dispatch(updateTentativeStartTime(time.start));
      dispatch(updateTentativeEndTime(time.end));
      dispatch(pushPopup(Popup.SCHEDULE));
    }
  }

  return (
    <div className={ styles.calendar }>
      <Calendar
        busySpans={ busySpans }
        hourSpan={ hourSpan }
        eventComponent={ Event }
        selectable={ true }
        onTryCreate={ onTryCreate }
        onSelecting={ onSelecting }
      />

      { isBrowser && busySpans.length === 0 && <NoEventsOverlay /> }
    </div>
  );
}
