import uniqBy from 'lodash.uniqby';
import moment from 'moment-timezone';
moment.tz.setDefault('America/Los_Angeles');
export default moment;
export function uniq(moments) {
  return uniqBy(moments, function (moment) {
    return moment.unix();
  });
}
export function comparator(a, b) {
  return a.diff(b);
}
export function min(a, b) {
  if (a.isBefore(b)) {
    return a;
  }

  return b;
}
export function max(a, b) {
  if (a.isAfter(b)) {
    return a;
  }

  return b;
}