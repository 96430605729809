import React from 'react';
import styles from './Loading.module.scss';
import Div100vh from 'react-div-100vh';

export default function Loading() {
  return (
    <Div100vh>
      <div className={ styles.container }>
        <div className={ styles.loader }>
          <div></div><div></div><div></div><div></div>
        </div>
      </div>
    </Div100vh>
  );
}
