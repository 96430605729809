import React from 'react';
import styles from './EditingDrawer.module.scss';
import { useView } from '@/slices/view';
import Open from './Open';
import Closed from './Closed';
import { useMe } from '@/slices/me';
import { isBrowser } from 'react-device-detect';

export default function EditingDrawer() {
  const { drawerOpen } = useView();
  const me = useMe();
  const open = me && drawerOpen;

  if(isBrowser) {
    return (
      <div className={ styles.drawer }>
        <section>
          <h1>Participants</h1>
          <Closed />
        </section>


        { me && (
          <section>
            <h1>Your calendars</h1>
            <Open />
          </section>
        ) }
      </div>
    );
  }

  return (
    <div className={ styles.drawer }>
      { open && <Open /> }
      { !open && <Closed /> }
    </div>
  );
}
