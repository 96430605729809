import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { beginScheduling } from '@/slices/scheduling';
import moment from '@shared/services/moment';
import { useCanSchedule } from '@/slices/meetup';
import { useDragAction, DragAction } from '@/slices/calendar';
import './EventContainerWrapper.scss';
import { isBrowser } from 'react-device-detect';

const longPressTime = 750; // milliseconds

export default function EventContainerWrapper({ children, slotMetrics }) {
  // Use refs instead of staet so we don't have to re-render all the time
  const wrapper = useRef();
  const pressingSlot = useRef();
  const pressTimeout = useRef();

  const canSchedule = useCanSchedule();
  const dispatch = useDispatch();
  const dragAction = useDragAction();

  function startPress({ clientY }) {
    if(isBrowser) {
      return;
    }

    if(pressTimeout.current) {
      return;
    }

    if(dragAction !== DragAction.NONE) {
      return;
    }

    // This rule is consistent with the one to show the "Schedule" button in <EditingHeader>
    if(!canSchedule) {
      return;
    }

    pressingSlot.current = slotMetrics.closestSlotFromPoint({ y: clientY }, wrapper.current.getBoundingClientRect());
    pressTimeout.current = setTimeout(finishPress, longPressTime);
  }

  function stopPress() {
    if(!pressTimeout.current) {
      return;
    }

    clearTimeout(pressTimeout.current);
    pressTimeout.current = null;
  }

  function finishPress() {
    pressTimeout.current = null;
    dispatch(beginScheduling(moment(pressingSlot.current).unix()));
  }

  // ref must be on the actual child because the child has absolute positioning and we need its bounding box
  return (
    <div
      onTouchStart={ startPress }
      onTouchEnd={ stopPress }
      onMouseDown={ startPress }
      onMouseUp={ stopPress }
      className={ dragAction === DragAction.ADD_BUSY_SPAN ? 'EventContainerWrapper_addingManually' : undefined }
    >
      { React.cloneElement(children, { ref: wrapper }) }
    </div>
  );
}
